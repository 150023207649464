import React, { useState,useEffect } from "react";
import { scSubscriptionResourcesBySystemClientIdRootQuery } from "./clientGqlQuery";
import { Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import DraggableTable from "../common/DraggableTable";

const defaultSorted = [
  {
    id: "module_name",
    desc: false,
  },
];
const defaultPageSize = 20;
const defaultPage = 1;
const ClientResourceList = (props) => {
  const {
    data: scSubscriptionResourcesBySystemClientIdData,
    loading: scSubscriptionResourcesBySystemClientIdLoading,
    refetch,
    error: scSubscriptionResourcesBySystemClientIdError,
    fetchMore: scSubscriptionResourcesBySystemClientIdFetchMore,
  } = useQuery(scSubscriptionResourcesBySystemClientIdRootQuery, {
    variables: {
      systemClientId: parseInt(props.systemClientId),
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    // onCompleted: (data) => {      
    //   setSubscribedResourcesList(
    //     data &&   data.scSubscriptionResourcesBySystemClientId && data.scSubscriptionResourcesBySystemClientId.scSubscriptionResources && data.scSubscriptionResourcesBySystemClientId.scSubscriptionResources
    //       .length > 0
    //       ? cloneDeep(
    //           data.scSubscriptionResourcesBySystemClientId
    //             .scSubscriptionResources
    //         )
    //       : []
    //   );
    //   setPages(
    //     data &&   data.scSubscriptionResourcesBySystemClientId && data.scSubscriptionResourcesBySystemClientId.scSubscriptionResources && data.scSubscriptionResourcesBySystemClientId.scSubscriptionResources
    //       ? Math.ceil(
    //           data.scSubscriptionResourcesBySystemClientId.rowCount /
    //             defaultPageSize
    //         )
    //       : 1
    //   );
      
    // },
    fetchPolicy: "network-only",
  });

  useEffect(() => {    
    if (
      scSubscriptionResourcesBySystemClientIdData &&
      scSubscriptionResourcesBySystemClientIdData.scSubscriptionResourcesBySystemClientId &&
      scSubscriptionResourcesBySystemClientIdData.scSubscriptionResourcesBySystemClientId
        .scSubscriptionResources
    ) {
      setSubscribedResourcesList(
        scSubscriptionResourcesBySystemClientIdData.scSubscriptionResourcesBySystemClientId
          .scSubscriptionResources
      );
      setPages(
        scSubscriptionResourcesBySystemClientIdData.scSubscriptionResourcesBySystemClientId
          .scSubscriptionResources
          ? Math.ceil(
              scSubscriptionResourcesBySystemClientIdData
                .scSubscriptionResourcesBySystemClientId.rowCount / defaultPageSize
            )
          : 1
      );
    } else {
      setSubscribedResourcesList([]);
    }
  }, [scSubscriptionResourcesBySystemClientIdData]);

  
  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const [subscribedResourcesList, setSubscribedResourcesList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      HeaderText: "Resource Name",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Resource Name"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "resource_name")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "resource_name",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.resourceName.charAt(0) +
              props.original.resourceName.slice(1).toLowerCase()}
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Resource Type",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Resource Type"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "resource_type")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "resource_type",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.resourceType.charAt(0) +
              props.original.resourceType
                .slice(1)
                .toLowerCase()
                .replaceAll("_", " ")}
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Reset Cycle",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Reset Cycle"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "reset_cycle").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "reset_cycle",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.resetCycle
              ? props.original.resetCycle.charAt(0) +
                props.original.resetCycle.slice(1).toLowerCase()
              : "-"}
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Included Count",
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>
              Included Count
            </Typography>
          </span>
        </div>
      ),
      id: "included_name",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.includedCount}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Ratio",
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>Ratio</Typography>
          </span>
        </div>
      ),
      id: "ratio",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.ratio}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Max Count",
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>Max Count</Typography>
          </span>
        </div>
      ),
      id: "max_count",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.maxCount}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "PPAU",
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>PPAU</Typography>
          </span>
        </div>
      ),
      id: "price_per_additional_count",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.pricePerAdditionalUnit > 0
              ? "$ " + props.original.pricePerAdditionalUnit
              : "-"}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
  ];

  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      systemClientId: parseInt(props.systemClientId),
      sortedBy: sortedArray,
      limit: pageSize || 10,
      offset: page || 1,
    };
    refetch(newQueryVariables);
  };

  var height = props.height || window.innerHeight - 165;
  return (
    <div style={{ padding: 8 }} className="reactTableWraper">
      <DraggableTable
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        pages={pages} // Display the total number of pages
        loading={scSubscriptionResourcesBySystemClientIdLoading} // Display the loading overlay when we need it
        onFetchData={fetchData} // Request new data when things change
        data={subscribedResourcesList}
        expanderDefaults={{ width: 0 }}
        columns={columns}
        resizable={true}
        noDataText="No Record Found"
        minRows={1}
        defaultSorted={sortedArray}
        pageSizeOptions={[20, 25, 50, 100]}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        onPageSizeChange={(sz) => {
          setCurrentPage(0);
          setPageSize(sz);
        }}
        multiSort={true}
        style={{
          maxHeight: height,
          cursor: "default",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps)(ClientResourceList);
