import React, { useState } from "react";
import { Typography, Button, TextField } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useMutation } from "@apollo/client";
import { sendResetPasswordEmailMutation } from "./resetPasswordGQLQuery";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    marginTop: 16
  },
  fontMedium: {
    color: "#00a0e3 !important",
    textDecoration: "none",
    fontWeight: "600",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  smallText: {
    color: "#192d3e",
    margin: "15px 0px 20px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px"
  },
  headerTitle: {
    color: "#192d3e",
    margin: "12px 0px 20px",
    fontWeight: "600",
    textTransform: "capitalize"
  },
  errorMsg: {
    color: "#f44336",
    fontSize: "14px",
    minHeight: "1em",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "14px",
    border: "1px solid #f44336a1",
    padding: "10px 14px",
    marginBottom: "10px",
    background: "#fdedee",
    borderRadius: "4px"
  },
  successMsg: {
    color: "green",
    fontSize: "14px",
    minHeight: "1em",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "14px",
    border: "1px solid green",
    padding: "10px 14px",
    marginBottom: "10px",
    background: "rgba(0, 128, 0, 0.07)",
    borderRadius: "4px"
  },
  textField: {
    marginBottom: 16
  },
  wrapperButton: {
    marginTop: 16,
    textTransform: "capitalize",
    background: "rgb(56, 125, 255) !important"
  }
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  const [forgetPasswordMsg, setForgetPasswordMsg] = useState(false);
  const [forgetPasswordErr, setForgetPasswordErr] = useState(false);

  const [sendResetPasswordEmail] = useMutation(sendResetPasswordEmailMutation);

  const handleSubmitForm = (values) => {
    if (values.resetEmail) {
      sendResetPasswordEmail({
        variables: {
          email: values.resetEmail
        }
      })
        .then((dataItem) => {
          setForgetPasswordErr(false);
          setForgetPasswordMsg(
            "Please check your email for reset password link."
          );
        })
        .catch((error) => {
          console.log(error);
          setForgetPasswordErr(true);
          setForgetPasswordMsg("Email does not exist.");
        });
    }
  };

  return (
    <div className="reactTableWraper" style={{ height: "86vh" }}>
      {/* <div className="flex flex-col items-center justify-center w-full mt-32">
          <img
            className="w-128"
            src="assets/images/logos/logo-lg.png"
            alt="logo"
          />
        </div> */}
      <div style={{ width: 405, margin: "auto" }}>
        <div className="flex flex-col items-center justify-center mb-16">
          <Typography
            variant="h6"
            className={clsx(classes.headerTitle, "md:w-full text-center")}
          >
            Reset password
          </Typography>
          <p className={clsx(classes.smallText, "text-center mt-0 mb-0")}>
            Please enter the email address you'd like to send the reset password
            link.
          </p>
        </div>
        <Formik
          initialValues={{ resetEmail: "" }}
          enableReinitialize
          onSubmit={(values) => handleSubmitForm(values)}
          validationSchema={Yup.object().shape({
            resetEmail: Yup.string()
              .email("Please enter a valid email.")
              .required("Enter your email")
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <div className={classes.textField}>
                  <TextField
                    error={errors.resetEmail && touched.resetEmail}
                    helperText={
                      errors.resetEmail &&
                      touched.resetEmail &&
                      errors.resetEmail
                    }
                    label="Email"
                    name="resetEmail"
                    type="email"
                    variant="outlined"
                    value={values.resetEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    fullWidth
                    required
                    size={"small"}
                  />
                </div>
                {forgetPasswordMsg && (
                  <div className={clsx(classes.wrapper)}>
                    <p
                      className={
                        forgetPasswordErr
                          ? classes.errorMsg
                          : classes.successMsg
                      }
                    >
                      {forgetPasswordMsg}
                    </p>
                  </div>
                )}
                <Button
                  type="button"
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={clsx(classes.wrapperButton, "w-full mx-auto")}
                >
                  Send Reset Password Email
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
