import React, { useState, useEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import ConfirmDialog from "../common/ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import ActionOptionsButton from "../common/ActionOptionsButton";
import {
  Typography,
  Popover,
  Chip,
  FormControl,
  DialogContent,
  Select as MUISelect,
  Button,
  Grid,
  Input,
  Dialog,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  IconButton,
  MenuItem,
  ListItemText
} from "@material-ui/core";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import Select from "react-select";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import queryHistory from "../../lib/queryHistroy";
import DraggableTable from "../common/DraggableTable";
import {
  getReleaseNotesRootQuery,
  deleteReleaseNotesMutation
} from "./releaseNotesGQLQuery";
import AddEditReleaseNotesModal from "./AddEditReleaseNotesModal";

const defaultPageSize = 20;
const defaultPage = 1;

const ReleaseNotes = (props) => {
  const divRef = React.useRef();
  let [confirmId, setConfirmId] = useState(0);
  let [deleteObj, setDeleteObj] = useState({});
  const [releaseNotesList, setReleaseNotesList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const [releaseNoteeObj, setReleaseNoteeObj] = useState({});
  var height = window.innerHeight - 150;
  var width = window.innerWidth;
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [headerDialogTitle, setHeaderDialogTitle] = useState(
    "Add Release Notes"
  );
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [releaseNoteData, setReleaseNoteDate] = useState({});

  const columns = [
    {
      Header: "Release Date",
      sortable: false,
      Cell: (props) => {
        return (
          <span>
            {props.original.releaseDate
              ? moment(props.original.releaseDate).format("YYYY-MM-DD")
              : ""}
          </span>
        );
      }
    },
    {
      Header: "Release Type",
      sortable: false,
      Cell: (props) => {
        return (
          <>
            <span>{props.original.releaseType}</span>
          </>
        );
      }
    },
    {
      Header: "Note",
      sortable: false,
      Cell: (props) => {
        return (
          <span>{props.original.description.replace(/(<([^>]+)>)/gi, "")}</span>
        );
        // return (
        //   <Typography
        //     style={{ height: 30 }}
        //     dangerouslySetInnerHTML={{
        //       __html: props.original.description,
        //     }}
        //     className="dangerouslyInnerHTMLStyle"
        //   >
        //     {/* {props.original.description} */}
        //   </Typography>
        // );
      }
    },
    {
      Header: "Action",
      sortable: false,
      width: 75,
      Cell: (props) => {
        let options = [];
        options.push({
          label: "Edit",
          callBack: (ev) => {
            // ev.stopPropagation();
            editRelease(props.original, true);
          }
        });
        options.push({
          label: (
            <ConfirmDialog
              title={"Confirm Delete"}
              body={"Are you sure you want to delete this record?"}
              open={true}
              firstButton={"Cancel"}
              secondButton={"Delete"}
              onConfirm={() => {
                setDeleteObj(props.original);
                let deleteObj = props.original;
                deleteRelease(props.original.id);
              }}
              onReject={() => {}}
            >
              Delete
            </ConfirmDialog>
          )
        });

        return <ActionOptionsButton options={options} />;
      }
    }
  ];

  const editRelease = (values, isEdit) => {
    setReleaseNoteeObj(values);
    setOpen(true);
    setHeaderDialogTitle("Edit Release Note");
    setIsEdit(isEdit);
  };

  const deleteRelease = (id) => {
    deleteReleaseNotes({
      variables: {
        releaseNotesId: id
      }
    })
      .then((item) => {
        releaseNotesrefetch().then((i) => {
          let vData = cloneDeep( i && i.data && i.data.releaseNotes ? i.data.releaseNotes : {
            releaseNote: [],
            rowCount: 0
          });
          setReleaseNotesList(
            vData.releaseNote.sort((a, b) => (a > b ? -1 : 1))
          );
          setPages(
            vData.releaseNote
              ? Math.ceil(
                vData.rowCount / pageSize
                )
              : 1
          );
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    data: releaseNotesPaginationData,
    loading: releaseNotesPaginationLoading,
    refetch: releaseNotesrefetch,
    error: getReleaseNotesQueryError,
    fetchMore: getReleaseNotesQueryFetchMore
  } = useQuery(getReleaseNotesRootQuery, {
    variables: {
      releaseType: null,
      limit: defaultPageSize,
      offset: defaultPage
    },

    fetchPolicy: "network-only"
  });

  const { classes } = props;
  const [deleteReleaseNotes] = useMutation(deleteReleaseNotesMutation);

  useEffect(() => {
    if (
      releaseNotesPaginationData &&
      releaseNotesPaginationData.releaseNotes &&
      releaseNotesPaginationData.releaseNotes.releaseNote
    ) {
      // setReleaseNotesList(releaseNotesPaginationData.releaseNotes.releaseNote
      setReleaseNotesList(
        releaseNotesPaginationData &&
          releaseNotesPaginationData.data &&
          releaseNotesPaginationData.data.releaseNotes.releaseNote
      );
      setPages(
        releaseNotesPaginationData.releaseNotes.releaseNote
          ? Math.ceil(
              releaseNotesPaginationData.releaseNotes.rowCount / defaultPageSize
            )
          : 1
      );
    }
  }, [releaseNotesPaginationData]);

  const fetchData = (state, instance) => {
    // setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };
  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      limit: pageSize,
      offset: page
    };

    let historyFilters = {
      limit: pageSize,
      offset: page
    };
    queryHistory.set(
      props,
      "getRelaseNotesPaginationAndGroupByQuery",
      historyFilters
    );
    releaseNotesrefetch(newQueryVariables).then((i) => {
      setReleaseNotesList(i && i.data && i.data.releaseNotes.releaseNote);
      setPages(
        i && i.data && i.data.releaseNotes.releaseNote
          ? Math.ceil(i && i.data && i.data.releaseNotes.rowCount / pageSize)
          : 1
      );
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdit(false);
    setHeaderDialogTitle("Add Release Notes");
  };
  let [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setReleaseNoteeObj({});

    setOpen(false);
  };

  const handleAnchorElMenuOpen = (event) => {
    setAnchorEl(divRef.current);
  };

  const handleAnchorElMenuClose = () => {
    setAnchorEl(null);
  };

  let openPopover = Boolean(anchorEl);

  const clearAllData = async () => {
    queryHistory.clear(props);
    handleAnchorElMenuClose();
    // await getData(defaultPageSize, defaultPage);
  };
  return (
    <div>
      {releaseNotesPaginationLoading && <Spinner />}
      <div className="reactTableWraper">
        <div className="header-Wraper justifyContentEnd">
          <div className="addButtonStyle" style={{ marginLeft: 8 }}>
            <Button
              size="medium"
              variant="contained"
              className="btnSmall panelAddButton p-0"
              style={{
                maxWidth: 139,
                height: 34,
                backgroundColor: "#387DFF"
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  textTransform: "capitalize"
                }}
              >
                Add Release Notes
              </Typography>
            </Button>
          </div>
        </div>
        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={pages} // Display the total number of pages
          loading={releaseNotesPaginationLoading} // Display the loading overlay when we need it
          onFetchData={fetchData} // Request new data when things change
          data={releaseNotesList}
          expanderDefaults={{ width: 0 }}
          columns={columns}
          // resizable={true}
          noDataText="No Record Found"
          minRows={1}
          // defaultSorted={sortedArray}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          page={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          onPageSizeChange={(sz) => {
            setCurrentPage(0);
            setPageSize(sz);
          }}
          // multiSort={true}
          style={{
            maxHeight: height - 50,
            cursor: "default"
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (ev) => {
                setNoteDialogOpen(true);
                setReleaseNoteDate(rowInfo.original);
              }
            };
          }}
        />

        <Dialog
          // fullWidth={true}
          // scroll={"paper"}
          maxWidth={"sm"}
          disableBackdropClick
          // classes={{
          //   paperFullWidth: classes.paperFullWidth,
          // }}
          open={open}
          onEscapeKeyDown={handleClose}
          onClose={handleClose}
          // classes={{
          //   paperFullWidth: classes.paperFullWidth,
          // }}
        >
          {/* <Dialog open={open} onClose={handleClose} maxWidth="sm"> */}
          <DialogTitle
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff"
              // width: width / 2 - 150,
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {headerDialogTitle}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              // height: height - 100,
              // width: width / 2 - 150,
              backgroundColor: "white"
            }}
            // classes={{
            //   root: classes.dialogContentRoot,
            // }}
          >
            <AddEditReleaseNotesModal
              isEdit={isEdit}
              releaseNoteeObj={releaseNoteeObj}
              onSuccess={(data) => {
                releaseNotesrefetch().then((i) => {
                  setReleaseNotesList(
                    i && i.data && i.data.releaseNotes.releaseNote
                  );
                  setPages(
                    i && i.data && i.data.releaseNotes.releaseNote
                      ? Math.ceil(
                          i && i.data && i.data.releaseNotes.rowCount / pageSize
                        )
                      : 1
                  );
                });
              }}
              handleClose={handleClose}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          maxWidth={"md"}
          disableBackdropClick
          // classes={{
          //   paperFullWidth: classes.paperFullWidth,
          // }}
          open={noteDialogOpen}
          onClose={() => {
            setNoteDialogOpen(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          {/* <Dialog open={open} onClose={handleClose} maxWidth="sm"> */}
          <DialogTitle
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff"
              // width: width / 2 - 150,
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                Release Note
              </Typography>
              <IconButton onClick={() => setNoteDialogOpen(false)}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              height: height,
              // width: width / 2 - 150,
              backgroundColor: "white"
            }}
            // classes={{
            //   root: props.classes.dialogContentRoot,
            // }}
          >
            <Grid Grid container direction="row">
              <ListItemText
                className="cardListItem"
                primary={
                  <Typography variant="caption" className="cardItemprimary">
                    Release Date
                  </Typography>
                }
                secondary={
                  <Typography className="cardItemsecondary">
                    {releaseNoteData.releaseDate}
                  </Typography>
                }
              />
              <ListItemText
                className="cardListItem"
                primary={
                  <Typography variant="caption" className="cardItemprimary">
                    Release Type
                  </Typography>
                }
                secondary={
                  <Typography className="cardItemsecondary">
                    {releaseNoteData.releaseType}
                  </Typography>
                }
              />
            </Grid>
            <ListItemText
              className="cardListItem"
              primary={
                <Typography variant="caption" className="cardItemprimary">
                  Note
                </Typography>
              }
              secondary={
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: releaseNoteData.description
                  }}
                />
              }
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

const styles = () => ({
  paperFullWidth: {
    overflowY: "visible"
  },
  dialogContentRoot: {
    overflowY: "visible"
  }
});
const mapStateToProps = ({}) => {
  return {};
};

export default withStyles(styles)(connect(mapStateToProps)(ReleaseNotes));
