import React, { Component } from "react";
import {
  Grid,
  MenuItem,
  InputLabel,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  FormHelperText,
  Button,
  FormControl,
  Select as MUISelect
} from "@material-ui/core";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { Formik } from "formik";
import moment from "moment-timezone";
import {
  addReleaseNotesMutation,
  editReleaseNotesMutation,
  getReleaseNotesRootQuery
} from "./releaseNotesGQLQuery";
import "react-datepicker/dist/react-datepicker.css";
import RichTextEditor from "../common/RichTextEditor";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const animatedComponents = makeAnimated();
class AddEditReleaseNotesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleIds: [],
      queryDidLoad: false,
      initForm: {
        id: 0,
        releaseDate: null,
        releaseType: "",
        description: ""
      }
    };
  }

  handleSubmit = (values) => {
    let releaseData = {};
    releaseData.id = values.id;
    releaseData.releaseDate = moment(values.releaseDate).format("YYYY-MM-DD");

    releaseData.releaseType = values.releaseType;
    releaseData.description =
      values.description &&
      values.description.getCurrentContent().hasText() === true
        ? draftToHtml(convertToRaw(values.description.getCurrentContent()))
        : "";

    if (this.props.isEdit) {
      this.props
        .editReleaseNotesMutation({
          variables: {
            ReleaseNotesData: releaseData
          },
          refetchQueries: [
            {
              query: getReleaseNotesRootQuery,
              variables: { limit: 20, offset: 1 }
            }
          ]
        })
        .then((item) => {
          if (this.props.onSuccess) {
            this.props.onSuccess(
              item && item.data && item.data.editReleaseNotes.id
            );
          }
          this.props.handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.props
        .addReleaseNotesMutation({
          variables: { ReleaseNotesData: releaseData }
        })
        .then((item) => {
          if (this.props.onSuccess) {
            this.props.onSuccess(
              item && item.data && item.data.addReleaseNotes.id
            );
          }
          this.props.handleClose();
        })
        .catch((err) => {
          this.props.handleClose();
          console.log("err", err);
        });
    }
  };

  initializeScreen = () => {
    if (this.props.isEdit && !this.state.queryDidLoad) {
      let note = "";

      if (this.props.isEdit) {
        let contentBlock = htmlToDraft(this.props.releaseNoteeObj.description);

        let contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );

        note = EditorState.createWithContent(contentState);
      } else {
        note = "";
      }
      this.setState(
        {
          initForm: {
            id: this.props.releaseNoteeObj.id,
            releaseDate: new Date(this.props.releaseNoteeObj.releaseDate),

            releaseType: this.props.releaseNoteeObj.releaseType,
            description: note
          }
        },
        () => {
          this.setState({
            queryDidLoad: true
          });
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    this.initializeScreen();

    return (
      <Formik
        initialValues={this.state.initForm}
        validateOnBlur={false}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          id: Yup.number(),
          releaseDate: Yup.date().required("Please select date"),
          releaseType: Yup.string()
            .min(1, "Please select release type")
            .required("Please select release type"),
          description: Yup.string().required("Please write the release notes")
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent
                classes={{
                  root: classes.dialogContentRoot
                }}
                style={{ paddingTop: 0, zIndex: 9 }}
              >
                <div>
                  <Grid container spacing={2} style={{ marginTop: 4 }}>
                    <Grid item xs={6}>
                      <DatePicker
                        selected={values.releaseDate}
                        // popperPlacement="bottom-top"
                        onChange={(e) => {
                          setFieldValue("releaseDate", e);
                        }}
                        error={
                          touched.releaseDate && errors.releaseDate
                            ? true
                            : false
                        }
                        helperText={
                          errors.releaseDate &&
                          touched.releaseDate &&
                          errors.releaseDate
                        }
                        placeholderText={"Start Date"}
                        dateFormat="MMMM d, yyyy"
                        className={
                          errors.releaseDate
                            ? "datePickerComponent errorDisplay"
                            : "datePickerComponent"
                        }
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        style={{ display: "flex" }}
                        variant="outlined"
                        className="w-full"
                        size="small"
                        error={errors.releaseType && touched.releaseType}
                      >
                        <InputLabel
                          id="demo-customized-select-label"
                          className={classes.lableOutline}
                        >
                          Select release type
                        </InputLabel>
                        <MUISelect
                          value={values.releaseType}
                          components={animatedComponents}
                          className="display-flex w-full"
                          fullWidth={true}
                          variant="outlined"
                          label="type"
                          onChange={(e) => {
                            setFieldValue("releaseType", e.target.value);
                          }}
                        >
                          <MenuItem value={"WEB_SBIZZL"}>WEB_SBIZZL</MenuItem>
                          <MenuItem value={"CUSTOMER_PORTAL_SBIZZL"}>
                            CUSTOMER_PORTAL_SBIZZL
                          </MenuItem>
                        </MUISelect>
                        {errors.releaseType && touched.releaseType ? (
                          <FormHelperText>
                            {errors.releaseType &&
                              touched.releaseType &&
                              errors.releaseType}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} className="flex items-center">
                      <RichTextEditor
                        editorState={values.description}
                        // value={values.description}
                        onEditorStateChange={(data) => {
                          setFieldValue("description", data);
                        }}
                        editorStyle={{
                          fontSize: 12,
                          height: 180
                        }}
                        placeholder="Description"
                        name="description"
                        id="description"
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "history"
                          ],
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: {
                            inDropdown: true,
                            defaultTargetOption: "_blank"
                          },
                          history: { inDropdown: true }
                        }}
                        error={
                          errors.description && touched.description
                            ? true
                            : false
                        }
                        helperText={
                          errors.description &&
                          touched.description &&
                          errors.description
                        }
                      />
                      {errors.description && touched.description ? (
                        <FormHelperText style={{ color: "red" }}>
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button onClick={this.props.handleClose} color="primary">
                    Cancel
                  </Button>

                  <Button
                    // disabled={isSubmitting}
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Save
                  </Button>
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px"
  },
  paperFullWidth: {
    overflowY: "visible"
  },
  dialogContentRoot: {
    overflowY: "visible"
  },
  textareaAutosize: {
    width: "100%",
    padding: "5px 10px",
    resize: "none"
  }
});
AddEditReleaseNotesModal.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = () => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(
    compose(
      graphql(addReleaseNotesMutation, { name: "addReleaseNotesMutation" }),
      graphql(editReleaseNotesMutation, { name: "editReleaseNotesMutation" })
    )(AddEditReleaseNotesModal)
  )
);
