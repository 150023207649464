import React, { useState, useEffect } from "react";
import {
  getCarrierServicesByFilterPaginationQuery
} from "./CarrierServiceGqlQuery";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Icon,
  Input,
  Popover,
  Grid
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DraggableTable from "../common/DraggableTable";
import ActionOptionsButton from "../common/ActionOptionsButton";
import CloseIcon from "@material-ui/icons/Close";
import Spinner from "../common/Spinner";
import queryHistroy from "../../lib/queryHistroy";
import CarrierServiceModal from "./CarrierServiceModal";

const defaultSorted = [
  {
    id: "carrier",
    desc: false,
  },
];

const defaultPageSize = 20;
const defaultPage = 1;

const CarrierService = (props) => {
  
  let historyFilter = queryHistroy.get(props, "filters");
  historyFilter = historyFilter ? historyFilter : {};
  
  let historyVars =  queryHistroy.get(props, "getCarrierServicesByFilterPaginationQuery") || {};

  let historyObj = {
    ...historyVars,
    sortedBy: historyFilter.sortedBy || defaultSorted,
    limit: historyFilter.limit ? historyFilter.limit : defaultPageSize,
    offset: historyFilter.offset ? historyFilter.offset : defaultPage,
    searchText: historyFilter.searchText || null,
    filtersData: {
      ...historyVars.filtersData,
      carrier:
        historyFilter && historyFilter.filtersData && historyFilter.filtersData.carrier
          ? historyFilter.filtersData.carrier : null,          
    },
  };

  const divRef = React.useRef();

  const [carriersList, setCarriersList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  let [sortedArray, setSortedArray] = useState(historyObj.sortedBy ? historyObj.sortedBy : defaultSorted);
  let [pageSize, setPageSize] = useState( historyObj.limit ? historyObj.limit : defaultPageSize);
  const [titleName, setTitleName] = useState("Add carrier service");
  let [currentPage, setCurrentPage] = useState(historyObj.offset ? historyObj.offset - 1 : 0);
  const [open, setOpen] = useState(false);
  let [searchText, setSearchText] = useState(historyFilter.searchText ? historyFilter.searchText : null);
  const [loader, setLoader] = useState(false);
  const [screenInitialized, setScreenInitialized] = useState(false);
  let [ selectedCarrier, setSelectedCarrier] = useState( historyFilter.filtersData && historyFilter.filtersData.carrier ? historyFilter.filtersData.carrier : "All");
  let [anchorEl, setAnchorEl] = React.useState(null);
  const [serviceData, setServiceData] = useState(null);

  let openDialog = Boolean(anchorEl);

  const columns = [
    {
      HeaderText: "Carrier",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Carrier"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "carrier").length > 0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      sortable: true,
      multiSort: true,
      id: "carrier",
      Cell: (props) => {
        return (
          <>
            <span>{props.original.carrier}</span>
          </>
        );
      },
    },
    {
      HeaderText: "Service Name",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Service Name"}
            
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "service_name").length > 0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      sortable: true,
      multiSort: true,
      id: "service_name",
      Cell: (props) => {
        return (
          <>
            <span>{props.original.serviceName}</span>
          </>
        );
      },
    },
    {
      HeaderText: "Service Code",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Service Code"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "service_code").length > 0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      sortable: true,
      multiSort: true,
      id: "service_code",
      Cell: (props) => {
        return (<>
            <span>{props.original.serviceCode}</span>
        </>
        );
      },
    },
    {
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Order Service Name"}
          </span>
        </div>
      ),
      sortable: true,
      multiSort: true,
      Cell: (props) => {
        return (
          <>
            <span>{props.original.orderServiceName}</span>
          </>
        );
      },
    },
    {
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">{"Order Service Code"}</span>
        </div>
      ),
      sortable: true,
      multiSort: true,
      Cell: (props) => {
        return (<>
            <span>{props.original.orderServiceCode}</span>
        </>
        );
      },
    },
    {
      Header: "Actions",
      sortable: false,
      Cell: (props) => {
        let options = [];
        options.push({
          label: "Edit",
          callBack: () => {
            editService(props.original);
          },
        });
        return <ActionOptionsButton options={options} />;
      },
    },
  ];

  const {
    data: getCarrierServicesPaginationQueryData,
    loading: getCarrierServicesPaginationQueryLoading,
    refetch: refetchCarrierServicesByFilterPaginationQry
  } = useQuery(getCarrierServicesByFilterPaginationQuery, {
    variables: historyObj ? historyObj : {
      searchText: null,
      filtersData: {
        carrier: "All"
      },
      sortedBy: historyFilter && historyFilter.sortedBy ? historyFilter.sortedBy : defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      getCarrierServicesPaginationQueryData &&
      getCarrierServicesPaginationQueryData.carrierServicesByFilterPagination &&
      getCarrierServicesPaginationQueryData.carrierServicesByFilterPagination.carrierServices
    ) {
      setCarriersList(
        getCarrierServicesPaginationQueryData.carrierServicesByFilterPagination.carrierServices
      );
      setPages(
        getCarrierServicesPaginationQueryData.carrierServicesByFilterPagination.carrierServices
          ? Math.ceil(
              getCarrierServicesPaginationQueryData.carrierServicesByFilterPagination.rowCount /
                pageSize
            )
          : 1
      );
    } else {
      setCarriersList([]);
    }
  }, [getCarrierServicesPaginationQueryData]);

  useEffect(()=> {
    if(!getCarrierServicesPaginationQueryLoading &&
      !screenInitialized) {
      initializeScreen();
      setScreenInitialized(true);
    }
  }, [getCarrierServicesPaginationQueryLoading,screenInitialized])

  const fetchData = (state, instance) => {

    if(getCarrierServicesPaginationQueryLoading) {
      return;
    };

    sortedArray = state.sorted;
    setSortedArray(state.sorted);
    
    let activePage =
    state.pageSize === pageSize && state.page ? state.page + 1 : 1;
    currentPage = activePage - 1;
    setCurrentPage(activePage - 1);
    getData(state.pageSize, activePage, state.sorted);
  };

  const editService = (data) => {    
    setServiceData({
      id: data.id,
      carrier: data.carrier,
      serviceName: data.serviceName,
      serviceCode: data.serviceCode,
      orderServiceName: data.orderServiceName,
      orderServiceCode: data.orderServiceCode,
    });
 
    setOpen(true);
    setTitleName("Edit carrier service");
  };

  const getData = async (pageSize, page, sortedArr) => {
    try {
      let historyVars = {
        searchText: searchText || null,
        filtersData: {
          carrier: selectedCarrier
        },
        sortedBy: sortedArr,
        limit: pageSize,
        offset: page
      };
      await queryHistroy.set(props, "getCarrierServicesByFilterPaginationQuery", historyVars);

      let newFilters = {
        filtersData: {
          carrier: selectedCarrier
        },
        searchText: searchText || null,
        sortedBy: sortedArr,
        limit: pageSize,
        offset: page
      };
      
      queryHistroy.set(props, "filters", newFilters);
      
      setCurrentPage(page-1);
      refetchCarrierServicesByFilterPaginationQry(historyVars);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const initializeScreen = () => {

    if(!getCarrierServicesPaginationQueryLoading) {

      let historyFilter = queryHistroy.get(props, "filters");
      historyFilter = historyFilter ? historyFilter : {};
      
      setPageSize(historyFilter.limit ? historyFilter.limit : defaultPageSize);
      pageSize = historyFilter.limit ? historyFilter.limit : defaultPageSize;
      
      currentPage = historyFilter.offset ? historyFilter.offset - 1 : 0;
      setCurrentPage(historyFilter.offset ? historyFilter.offset - 1 : 0);
      
      setSortedArray(historyFilter.sortedBy ? historyFilter.sortedBy : sortedArray)
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
    setTitleName("Add carrier service");
  };
  

  const handleClose = () => {
    setOpen(false);
    setServiceData(null);
  };

  var height = props.height || window.innerHeight - 200;
  
  const handleAnchorElMenuOpen = (event) => {
    setAnchorEl(divRef.current);
  };

  const handleAnchorElMenuClose = () => {
    setAnchorEl(null);
  };
  
  const clearAllData = async () => {
    queryHistroy.clear(props);
    selectedCarrier = "All";
    setSelectedCarrier("All");
    
    setAnchorEl(null);
    
    anchorEl = null;

    getData(pageSize, defaultPage, sortedArray);
  };


  return (
    <>
    {(getCarrierServicesPaginationQueryLoading || loader) && <Spinner />}
      <div className="reactTableWraper">
        <div className="header-Wraper justifyContentEnd">
          <div className="header-Wraper-right">
          
            <div className="formInputWraper">
              <Input
                placeholder={"Search"}
                className="flex flex-1 w-full"
                disableUnderline
                inputProps={{
                  "aria-label": "Search",
                  className: "inputStyle",
                }}
                value={searchText}
                onChange={async (e) => {
                  if (e.target.value) {
                    searchText = e.target.value;
                    setSearchText(e.target.value);
                  } else {
                    await setSearchText("");
                    searchText = "";
                    getData(pageSize, defaultPage, sortedArray);
                  }
                }}
                onKeyPress={(e) => {
                  if (e && e.key === "Enter") {
                    getData(pageSize, defaultPage, sortedArray);
                  }
                }}
              />
              <IconButton
                color="inherit"
                onClick={(ev) => {
                  ev.stopPropagation();
                  getData(pageSize, defaultPage, sortedArray);
                }}
              >
                <Icon>search</Icon>
              </IconButton>
            </div>
            <div className="header-Wraper-filterDiv">
              <Button
                ref={divRef}
                aria-describedby={"popover"}
                variant="contained"
                color="primary"
                onClick={handleAnchorElMenuOpen}
                style={{ marginLeft: 15, marginRight: 15 }}
              >
                <Icon>filter_alt</Icon>
              </Button>
              <div className="popoverOverflow">
                <Popover
                  id="popover"
                  open={openDialog}
                  anchorEl={anchorEl}
                  classes={{
                    paper: "popoverOverflow",
                  }}
                  onClose={handleAnchorElMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div className="popoverContentwraper">
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAnchorElMenuClose();
                        }}
                        style={{ color: "#0077C5", cursor: "pointer" }}
                      >
                        Cancel
                      </a>
                    </div>
                    <div className="popoverFormDatePickerWraper">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div
                            style={{ position: "relative", zIndex: 999}}
                            className="w-full mb-16"

                          >
                             <Typography
                              style={{marginBottom:5}}
                              className="w-full mb-8 text-14"
                            >
                              Carrier
                            </Typography>
                            <FormControl
                                  variant="outlined"
                                  className="w-full"
                                  size="small"
                                >
                                  <Select
                                    value={selectedCarrier}
                                    onChange={(evt) => {
                                      setSelectedCarrier(evt.target.value)
                                    }}
                                    className="w-full"
                                  >
                                    <MenuItem value={"All"}>
                                      {"All"}
                                    </MenuItem>
                                    <MenuItem value={"FedEx"}>
                                      {"FedEx"}
                                    </MenuItem>
                                    <MenuItem value={"UPS"}>
                                      {"UPS"}
                                    </MenuItem>
                                    <MenuItem value={"Inhouse"}>
                                      {"Inhouse"}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
            
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="popoverWraperFooter">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="cancelBtn"
                        onClick={() => {
                          clearAllData();
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="filterBtn"
                        onClick={() => {
                          queryHistroy.clear(props);
                          handleAnchorElMenuClose();
                          getData(pageSize, defaultPage, sortedArray);
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          <div className="addButtonStyle">
            <Button
              size="medium"
              variant="contained"
              className="btnSmall panelAddButton p-0"
              style={{
                maxHeight: 34,
                backgroundColor: "#387DFF",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                {"Add carrier service"}
              </Typography>
            </Button>
          </div>
        </div>
        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={pages} // Display the total number of pages
          loading={getCarrierServicesPaginationQueryLoading} // Display the loading overlay when we need it
          onFetchData={fetchData} // Request new data when things change
          data={carriersList}
          expanderDefaults={{ width: 0 }}
          columns={columns}
          resizable={true}
          noDataText="No Record Found"
          minRows={1}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          page={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          onPageSizeChange={(sz) => {
            setCurrentPage(0);
            setPageSize(sz);
          }}
          multiSort={true}
          defaultSorted={sortedArray}
          style={{
            maxHeight: height,
            cursor: "default",
          }}
        />
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
        >
          <DialogTitle            
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {titleName}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <CarrierServiceModal
            edit={serviceData && serviceData.id > 0 ? true : false}
            carrierServiceData={serviceData}
            handleClose={handleClose}
            onSuccess={()=> {
              getData(pageSize, defaultPage, sortedArray);
            }}
          />
        </Dialog>
      </div>
    </>
  );
};

const styles = (theme) => ({})

const mapStateToProps = ({}) => {
  return {};
};

export default withStyles(styles)(
  connect(mapStateToProps)(CarrierService)
);