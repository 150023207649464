import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Tabs,
  Box,
  Tab,
  IconButton,
  Icon,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import moment from "moment";
import {
  getSystemClientQuery,
  getSystemClientSubscriptionByIdRootQuery,
} from "./clientGqlQuery";
import ClientModuleList from "./ClientModuleList";
import ClientResourceList from "./ClientResourceList";
import ClientPriceList from "./ClientPriceList";
import Spinner from "../common/Spinner";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import EditExtendTrialModal from "./EditExtendTrialModal";
import EditExtendDueDateModal from "./EditExtendDueDateModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

const ClientsDetails = (props) => {
  const { classes } = props;

  const {
    data: SystemClientData,
    loading: SystemClientDataLoading,
    refetch: SystemClientDataRefetch,
    error: SystemClientDataError,
    fetchMore: SystemClientDataFetchMore,
  } = useQuery(getSystemClientQuery, {
    variables: {
      id: parseInt(props.match.params.Id),
    },
    fetchPolicy: "network-only",
  });

  const {
    data: SystemClientSubscriptionByIdData,
    loading: SystemClientSubscriptionByIdDataLoading,
    refetch: SystemClientSubscriptionByIdDataRefetch,
    error: SystemClientSubscriptionByIdDataError,
    fetchMore: SystemClientSubscriptionByIdFetchMore,
  } = useQuery(getSystemClientSubscriptionByIdRootQuery, {
    variables: {
      systemClientId: parseInt(props.match.params.Id),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    // setAnchorEl(divRef.current);
    if (
      SystemClientData &&
      SystemClientData.systemClients &&
      SystemClientData.systemClients.length
    ) {
      setSystemClientsList(SystemClientData.systemClients[0]);
    } else {
      setSystemClientsList([]);
    }
    if (
      SystemClientSubscriptionByIdData &&
      SystemClientSubscriptionByIdData.getSystemClientSubscriptionById
    ) {
      setSystemClientSubscriptionData(
        SystemClientSubscriptionByIdData.getSystemClientSubscriptionById
      );
    } else {
      setSystemClientSubscriptionData([]);
    }
  }, [SystemClientData, SystemClientSubscriptionByIdData]);
  
  const [systemClientsList, setSystemClientsList] = useState([]);
  const [
    systemClientSubscriptionData,
    setSystemClientSubscriptionData,
  ] = useState({});
  const [selectedTab, setSelectedTab] = useState("modules");
  const [openExtendTrial, setOpenExtendTrial] = useState(false);
  const [openExtendDueDate, setOpenExtendDueDate] = useState(false);

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };
  var height = window.innerHeight - 195;
  const goBack = () => {
    props.history.goBack();
    return true;
  };
  let statusColor =
    systemClientsList.customerStatus === "TRIAL"
      ? "#34c0eb"
      : systemClientsList.customerStatus === "SUSPENDED"
      ? "grey"
      : systemClientsList.customerStatus === "DELETED"
      ? "#fa1100"
      : systemClientsList.customerStatus === "EXPIRED"
      ? "#FF0000"
      : systemClientsList.customerStatus === "CANCELLED"
      ? "#FFA500"
      : systemClientsList.customerStatus === "ACTIVE"
      ? "green"
      : "";
  let endDate = systemClientSubscriptionData.subscriptionExpiryDate
    ? moment(systemClientSubscriptionData.subscriptionExpiryDate)
        .add(1, "d")
        .format("MM/DD/YYYY")
    : "-";
  let startDate = systemClientSubscriptionData.subscriptionStartDate
    ? moment(systemClientSubscriptionData.subscriptionStartDate).format(
        "MM/DD/YYYY"
      )
    : "-";

  const ownerName =
    systemClientsList &&
    systemClientsList.owner &&
    systemClientsList.owner.employeeDetail &&
    systemClientsList &&
    systemClientsList.owner &&
    systemClientsList.owner.employeeDetail &&
    systemClientsList.owner.employeeDetail.isCompany
      ? systemClientsList &&
        systemClientsList.owner &&
        systemClientsList.owner.employeeDetail &&
        systemClientsList.owner.employeeDetail.companyName
      : (systemClientsList &&
        systemClientsList.owner &&
        systemClientsList.owner.employeeDetail &&
        systemClientsList &&
        systemClientsList.owner &&
        systemClientsList.owner.employeeDetail &&
        systemClientsList.owner.employeeDetail.fName
          ? systemClientsList &&
            systemClientsList.owner &&
            systemClientsList.owner.employeeDetail &&
            systemClientsList.owner.employeeDetail.fName
          : "") +
        " " +
        (systemClientsList &&
        systemClientsList.owner &&
        systemClientsList.owner.employeeDetail &&
        systemClientsList &&
        systemClientsList.owner &&
        systemClientsList.owner.employeeDetail &&
        systemClientsList.owner.employeeDetail.mName
          ? " " + systemClientsList &&
            systemClientsList.owner &&
            systemClientsList.owner.employeeDetail &&
            systemClientsList.owner.employeeDetail.mName
          : "") +
        " " +
        (systemClientsList &&
        systemClientsList.owner &&
        systemClientsList.owner.employeeDetail &&
        systemClientsList &&
        systemClientsList.owner &&
        systemClientsList.owner.employeeDetail &&
        systemClientsList.owner.employeeDetail.lName
          ? " " + systemClientsList &&
            systemClientsList.owner &&
            systemClientsList.owner.employeeDetail &&
            systemClientsList.owner.employeeDetail.lName
          : "");

  return (
    <>
      <div>
        <IconButton
          style={{
            color: "#387DFF",
            zIndex: 9999,
            top: 9,
            position: "absolute",
          }}
          onClick={goBack}
        >
          <Icon fontSize="small">arrow_back</Icon>
        </IconButton>
      </div>
      <div style={{ height: height - 30 }}>
        <Grid direction="column" container spacing={2}>
          {SystemClientDataLoading &&
            SystemClientSubscriptionByIdDataLoading && <Spinner />}
          <Grid item xs={12}>
            <Card className="cardContentWraper">
              <CardContent>
                <Grid container direction="row" spacing={2}>
                  {systemClientsList.clientName && (
                    <Grid item xs={4}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              fontStretch: 1.25,
                              lineHeight: 1.25,
                              marginLeft: 10,
                            }}
                          >
                            Company Name
                          </Typography>
                        }
                        secondary={
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 400,
                              marginLeft: 10,
                            }}
                          >
                            {systemClientsList.clientName}
                          </Typography>
                        }
                      />
                    </Grid>
                  )}
                  
                  {systemClientsList &&
                    systemClientsList.owner &&
                    systemClientsList.owner.id && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Owner Name
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {ownerName}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}

                  {systemClientsList &&
                    systemClientsList.owner &&
                    systemClientsList.owner.employeeDetail && 
                    systemClientsList.owner.employeeDetail.phone && 
                    systemClientsList.owner.employeeDetail.phone.length ? (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Contact Number
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {systemClientsList.owner.employeeDetail.phone[0].phoneNo}
                            </Typography>
                          }
                        />
                      </Grid>
                    ): (<></>)}

                  {systemClientsList &&
                    systemClientsList.owner &&
                    systemClientsList.owner.employeeDetail && 
                    systemClientsList.owner.employeeDetail.email && 
                    systemClientsList.owner.employeeDetail.email.length ? (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Email
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {systemClientsList.owner.employeeDetail.email[0].email}
                            </Typography>
                          }
                        />
                      </Grid>
                    ):(<></>)}

                  {systemClientsList && 
                  systemClientsList.locationComb && 
                  systemClientsList.locationComb.length ? (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Address
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {systemClientsList.locationComb[0].addrLine1 + ", "} 
                              {systemClientsList.locationComb[0].addrLine2 ? ", " + systemClientsList.locationComb[0].addrLine2 : ""}
                              {systemClientsList.locationComb[0].city + ", " + systemClientsList.locationComb[0].state + ", " + 
                              systemClientsList.locationComb[0].country + " - " + systemClientsList.locationComb[0].pincode}
                            </Typography>
                          }
                        />
                      </Grid>
                    ):(<></>)}

                  {systemClientsList.customerStatus && (
                    <Grid item xs={4}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              fontStretch: 1.25,
                              lineHeight: 1.25,
                              marginLeft: 10,
                            }}
                          >
                            Status
                          </Typography>
                        }
                        secondary={
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 400,
                              marginLeft: 10,
                              color: statusColor,
                            }}
                          >
                            {systemClientsList.customerStatus.charAt(0) +
                              systemClientsList.customerStatus
                                .slice(1)
                                .toLowerCase() || ""} {" "} {systemClientsList.customerStatus === "TRIAL" ? (systemClientsList.subscription.trialEndDate
                                  ? " - " +
                                    parseInt(moment(systemClientsList.subscription.trialEndDate, "YYYY-MM-DD").diff(
                                      moment().format("YYYY-MM-DD"),
                                      "days"
                                    ) + 1)
                                  : "") +
                                " Days left"
                                : systemClientsList.customerStatus === "EXPIRED" ? <Button
                                variant="outlined"
                                color="primary"
                                className="cancelBtn"
                                style={{
                                  maxWidth: 132,
                                  height: 34,
                                }}
                                onClick={()=>{
                                  setOpenExtendTrial(true);
                                }}
                              >
                                Extend Trial
                              </Button> : systemClientsList.customerStatus === "SUSPENDED" ? <Button
                                variant="outlined"
                                color="primary"
                                className="cancelBtn"
                                style={{
                                  maxWidth: 160,
                                  height: 34,
                                }}
                                onClick={()=>{
                                  setOpenExtendDueDate(true);
                                }}
                              >
                                Extend Due Date
                              </Button> : ""}
                          </Typography>
                        }
                      />
                    </Grid>
                  )}

                  {systemClientSubscriptionData.id &&
                    systemClientSubscriptionData.planName && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Plan Name
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {systemClientSubscriptionData.planName}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                  {systemClientSubscriptionData.id &&
                    systemClientSubscriptionData.renewal && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Billing
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {systemClientSubscriptionData.renewal.charAt(0) +
                                systemClientSubscriptionData.renewal
                                  .slice(1)
                                  .toLowerCase()}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                  {systemClientSubscriptionData.subscriptionExpiryDate && (
                    <Grid item xs={4}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              fontStretch: 1.25,
                              lineHeight: 1.25,
                              marginLeft: 10,
                            }}
                          >
                            Next Billing Date
                          </Typography>
                        }
                        secondary={
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 400,
                              marginLeft: 10,
                            }}
                          >
                            {systemClientSubscriptionData.subscriptionExpiryDate
                              ? endDate.toString()
                              : startDate.toString()}
                          </Typography>
                        }
                      />
                    </Grid>
                  )}
                  {systemClientsList.createdDatetime && (
                    <Grid item xs={4}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              fontStretch: 1.25,
                              lineHeight: 1.25,
                              marginLeft: 10,
                            }}
                          >
                            Signup Date Time
                          </Typography>
                        }
                        secondary={
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 400,
                              marginLeft: 10,
                            }}
                          >
                            {moment(systemClientsList.createdDatetime)
                              .tz("America/New_York")
                              .format("MM-DD-YYYY HH:mm:ss")}
                          </Typography>
                        }
                      />
                    </Grid>
                  )}

{systemClientsList.login &&
                    systemClientsList.login.createdDatetime && (
                    <Grid item xs={4}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              fontStretch: 1.25,
                              lineHeight: 1.25,
                              marginLeft: 10,
                            }}
                          >
                            Last Login Date Time
                          </Typography>
                        }
                        secondary={
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 400,
                              marginLeft: 10,
                            }}
                          >
                            {moment(systemClientsList.login.createdDatetime)
                              .tz("America/New_York")
                              .format("MM-DD-YYYY HH:mm:ss")}
                          </Typography>
                        }
                      />
                    </Grid>
                  )}

                  {systemClientsList.deal &&
                    systemClientsList.deal.createdDatetime && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Last Deal Created Date Time
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {moment(systemClientsList.deal.createdDatetime)
                                .tz("America/New_York")
                                .format("MM-DD-YYYY HH:mm:ss")}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                  {systemClientsList.tasks &&
                    systemClientsList.tasks.createdDatetime && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Last Task Created Date Time
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {moment(systemClientsList.tasks.createdDatetime)
                                .tz("America/New_York")
                                .format("MM-DD-YYYY HH:mm:ss")}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}

                  {systemClientSubscriptionData.id &&
                    systemClientSubscriptionData.price && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Price
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {"$ " + systemClientSubscriptionData.price}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                  {(systemClientsList.customerStatus === "EXPIRED" ||
                    systemClientsList.customerStatus === "SUSPENDED") &&
                    systemClientsList.updatedDatetime && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Updated Date Time
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {moment(systemClientsList.updatedDatetime)
                                .tz("America/New_York")
                                .format("MM-DD-YYYY HH:mm:ss")}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                  {systemClientsList.customerStatus === "CANCELLED" &&
                    systemClientsList.subscription.cancellDatetime && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Cancelled Date Time
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {moment(
                                systemClientsList.subscription.cancellDatetime
                              )
                                .tz("America/New_York")
                                .format("MM-DD-YYYY HH:mm:ss")}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                    
                    {systemClientsList.customer &&
                    systemClientsList.customer.createdDatetime && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Last Customer Created Date Time
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {moment(systemClientsList.customer.createdDatetime)
                                .tz("America/New_York")
                                .format("MM-DD-YYYY HH:mm:ss")}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}

                    {systemClientsList.estimate &&
                    systemClientsList.estimate.createdDatetime && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Last Estimate Created Date Time
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {moment(systemClientsList.estimate.createdDatetime)
                                .tz("America/New_York")
                                .format("MM-DD-YYYY HH:mm:ss")}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}

{systemClientsList.invoice &&
                    systemClientsList.invoice.createdDatetime && (
                      <Grid item xs={4}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25,
                                marginLeft: 10,
                              }}
                            >
                              Last Invoice Created Date Time
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400,
                                marginLeft: 10,
                              }}
                            >
                              {moment(systemClientsList.invoice.createdDatetime)
                                .tz("America/New_York")
                                .format("MM-DD-YYYY HH:mm:ss")}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ maxHeight: height - 160 }}>
              <CardContent
                style={{
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="off"
                    style={{ padding: 0 }}
                    className="settingTabsHeaderTitle"
                  >
                    <Tab value="modules" label="Modules" />
                    <Tab value="resources" label="Resources" />
                    <Tab value="price" label="Price" />
                  </Tabs>
                </div>

                <TabPanel value={selectedTab} index="modules">
                  <div style={{ position: "relative" }} className="p-16">
                    <ClientModuleList
                      height={height - 250}
                      systemClientId={props.match.params.Id}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={selectedTab} index="resources">
                  <div style={{ position: "relative" }} className="p-16">
                    <ClientResourceList
                      height={height - 250}
                      systemClientId={props.match.params.Id}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={selectedTab} index="price">
                  <div style={{ position: "relative" }} className="p-16">
                    <ClientPriceList
                      height={height - 250}
                      systemClientId={props.match.params.Id}
                    />
                  </div>
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>          
        </Grid>
      </div>

{/* ------------------------Start extend trial dialog---------------------------- */}
      <Dialog
        fullWidth={true}
        scroll={"paper"}
        maxWidth="xs"
        style={{ overflow: "hidden" }}
        disableBackdropClick
        open={openExtendTrial}
        onEscapeKeyDown={()=>{
          setOpenExtendTrial(false);
        }}
        onClose={()=>{setOpenExtendTrial(false);}}
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
      >
          <DialogTitle
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {"Extend Trial"}
              </Typography>
              <IconButton onClick={()=>{setOpenExtendTrial(false);}}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
          classes={{
            root: classes.dialogContentRoot,
          }}
        >          
            <EditExtendTrialModal
              handleClose={()=>{setOpenExtendTrial(false)}}
              systemClientId = {parseInt(props.match.params.Id)}
              onSuccess={(data) => {
                SystemClientDataRefetch();
                SystemClientSubscriptionByIdDataRefetch();
              }}
            />
          </DialogContent>
        </Dialog>
        {/* ------------------------End extend trial dialog---------------------------- */}

        {/* ------------------------Start extend due date dialog---------------------------- */}
      <Dialog
        fullWidth={true}
        scroll={"paper"}
        maxWidth="xs"
        style={{ overflow: "hidden" }}
        disableBackdropClick
        open={openExtendDueDate}
        onEscapeKeyDown={()=>{
          setOpenExtendDueDate(false);
        }}
        onClose={()=>{setOpenExtendDueDate(false);}}
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
      >
          <DialogTitle
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {"Extend Trial"}
              </Typography>
              <IconButton onClick={()=>{setOpenExtendDueDate(false);}}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
          classes={{
            root: classes.dialogContentRoot,
          }}
        >          
            <EditExtendDueDateModal
              handleClose={()=>{setOpenExtendDueDate(false)}}
              systemClientId = {parseInt(props.match.params.Id)}
              onSuccess={(data) => {
                SystemClientDataRefetch();
                SystemClientSubscriptionByIdDataRefetch();
              }}
            />
          </DialogContent>
        </Dialog>
        {/* ------------------------End extend due date dialog---------------------------- */}
    </>
  );
};

const styles = () => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});

const mapStateToProps = ({}) => {
  return {};
};
export default withStyles(styles)(connect(mapStateToProps)(ClientsDetails));
