import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import {
  Typography,
  Button,
  Input,
  IconButton,
  Dialog,
  Select as MUISelect,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import DraggableTable from "../common/DraggableTable";
import "react-table/react-table.css";
import CloseIcon from "@material-ui/icons/Close";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../common/Spinner";
import UserModal from "./UserModal";
import {
  adminUsersPaginationAndGroupByRootQuery,
  deleteSuperAdminUserMutation,
} from "./usersGQLQuery";
import ActionOptionsButton from "../common/ActionOptionsButton";

const defaultPageSize = 20;
const defaultPage = 1;
const defaultSorted = [
  {
    id: "created_datetime",
    desc: true,
  },
];

class Users extends Component {
  constructor(props) {
    super(props);
    this.refReactTable = {};
    this.state = {
      userList: [],
      openDialog: false,
      deleteDialog: false,
      currentPage: 1,
      sortedArray: defaultSorted,
      queryDidLoad: false,
      displayMenu: false,
      pages: defaultPage,
      defaultPage: defaultPage,
      currentPage: 0,
      pageSize: defaultPageSize,
      columns: [
        {
          HeaderText: "Email",
          Header: (props) => (
            <div className="draggable-header">
              <span className="reactTable-headerText">
                {"Email"}
                {this.state.sortedArray &&
                  this.state.sortedArray.filter((iName) => iName.id === "email")
                    .length > 0 && (
                    <Icon className="sortableArrow">arrow_right_alt</Icon>
                  )}
              </span>
            </div>
          ),
          id: "email",
          Cell: (props) => {
            return (
              <>
                <span className="flex items-center capitalize">
                  {props.original.email}
                </span>
              </>
            );
          },
          sortable: true,
          multiSort: true,
        },
        {
          HeaderText: "Created Datetime",
          Header: (props) => (
            <div className="draggable-header">
              <span className="reactTable-headerText">
                {"Created Datetime"}
                {this.state.sortedArray &&
                  this.state.sortedArray.filter(
                    (iName) => iName.id === "created_datetime"
                  ).length > 0 && (
                    <Icon className="sortableArrow">arrow_right_alt</Icon>
                  )}
              </span>
            </div>
          ),
          id: "created_datetime",
          Cell: (props) => {
            return (
              <>
                <span className="flex items-center capitalize">
                  {moment(props.original.createdDatetime)
                    .tz("America/New_York")
                    .format("MM-DD-YYYY HH:mm:ss")}
                </span>
              </>
            );
          },
          sortable: true,
          multiSort: true,
        },
        {
          HeaderText: "Action",
          Header: (props) => (
            <div className="draggable-header">
              <span className="reactTable-headerText">{"Action"}</span>
            </div>
          ),
          id: "action",
          Cell: (rowData) => {
            let options = [];
            options.push({
              label: "Delete",
              // permission: "DELETE_ESTIMATE",
              callBack: () => {
                this.setState({
                  deleteDialog: true,
                  adminCombId: rowData.original.id,
                });
                this.handleCloseMenu();
              },
            });
            return <ActionOptionsButton options={options} />;
          },
          sortable: false,
          multiSort: false,
        },
      ],
    };
  }

  handleCloseMenu = () => {
    this.setState({ displayMenu: false, displayMenuId: 0 });
  };

  fetchData = async (state, instance) => {
    this.refReactTable = instance;
    this.setState({ sortedArray: state.sorted });
    this.getData(state.pageSize, state.page + 1);
  };

  getData = async (pageSize, page) => {
    if (this.props.adminUsersPaginationAndGroupByRootQuery.loading) {
      return;
    }

    let queryVariables = {
      searchtext: this.state.searchtext || null,
      sortedBy: this.state.sortedArray,
      limit: pageSize,
      offset: page,
    };

    this.props.adminUsersPaginationAndGroupByRootQuery
      .refetch(queryVariables)
      .then((i) => {
        this.setState({
          userList:
            this.props.adminUsersPaginationAndGroupByRootQuery &&
            this.props.adminUsersPaginationAndGroupByRootQuery
              .adminUsersPaginationAndGroupBy &&
            this.props.adminUsersPaginationAndGroupByRootQuery
              .adminUsersPaginationAndGroupBy
              ? this.props.adminUsersPaginationAndGroupByRootQuery
                  .adminUsersPaginationAndGroupBy.superAdminUsers
              : [],
          pages:
            this.props.adminUsersPaginationAndGroupByRootQuery &&
            this.props.adminUsersPaginationAndGroupByRootQuery
              .adminUsersPaginationAndGroupBy &&
            this.props.adminUsersPaginationAndGroupByRootQuery
              .adminUsersPaginationAndGroupBy
              ? Math.ceil(
                  this.props.adminUsersPaginationAndGroupByRootQuery
                    .adminUsersPaginationAndGroupBy.rowCount / pageSize
                )
              : 1,
          pageSize: pageSize,
          loading: false,
        });
      });
  };

  initializeScreen = () => {
    if (
      !this.props.adminUsersPaginationAndGroupByRootQuery.loading &&
      !this.state.queryDidLoad
    ) {
      this.setState(
        {
          pages:
            this.props.adminUsersPaginationAndGroupByRootQuery &&
            this.props.adminUsersPaginationAndGroupByRootQuery
              .adminUsersPaginationAndGroupBy
              ? Math.ceil(
                  this.props.adminUsersPaginationAndGroupByRootQuery
                    .adminUsersPaginationAndGroupBy.rowCount / defaultPageSize
                )
              : 1,
          userList:
            this.props.adminUsersPaginationAndGroupByRootQuery &&
            this.props.adminUsersPaginationAndGroupByRootQuery
              .adminUsersPaginationAndGroupBy &&
            this.props.adminUsersPaginationAndGroupByRootQuery
              .adminUsersPaginationAndGroupBy.superAdminUsers
              ? this.props.adminUsersPaginationAndGroupByRootQuery
                  .adminUsersPaginationAndGroupBy.superAdminUsers
              : [],
        },
        () => {
          this.setState({
            queryDidLoad: true,
          });
        }
      );
    }
  };

  handleClickOpen = () => {
    this.setState({
      openDialog: true,
      titleName: "Add User",
    });
  };

  handleClose = () => {
    this.setState({
      openDialog: false,
    });
  };
  handleDeleteAdminComb = () => {
    this.props
      .deleteSuperAdminUserMutation({
        variables: {
          adminCombId: this.state.adminCombId,
        },
      })
      .then((data) => {
        this.setState({
          deleteDialog: false,
        });

        this.props.adminUsersPaginationAndGroupByRootQuery
          .refetch()
          .then((data) => {
            this.setState({
              loading: false,
              userList: this.props.adminUsersPaginationAndGroupByRootQuery && 
              this.props.adminUsersPaginationAndGroupByRootQuery
                .adminUsersPaginationAndGroupBy && this.props.adminUsersPaginationAndGroupByRootQuery
                .adminUsersPaginationAndGroupBy
                ? this.props.adminUsersPaginationAndGroupByRootQuery
                    .adminUsersPaginationAndGroupBy.superAdminUsers
                : [],
              pages: this.props.adminUsersPaginationAndGroupByRootQuery && this.props.adminUsersPaginationAndGroupByRootQuery
                .adminUsersPaginationAndGroupBy && this.props.adminUsersPaginationAndGroupByRootQuery
                .adminUsersPaginationAndGroupBy
                ? Math.ceil(
                    this.props.adminUsersPaginationAndGroupByRootQuery
                      .adminUsersPaginationAndGroupBy.rowCount / defaultPageSize
                  )
                : 1,
            });
          });
      })
      .catch((error) => {
        this.setState({ loading: false, deleteDialog: false });
      });
  };

  render() {
    var height = window.innerHeight - 155;
    const { classes } = this.props;
    this.initializeScreen();

    return (
      <div className="reactTableWraper">
        {this.props.adminUsersPaginationAndGroupByRootQuery.loading && (
          <Spinner />
        )}
        <div className="header-Wraper justifyContentEnd">
          {/* <div className="header-Wraper-right">
            <div className="formInputWraper">
              <Input
                placeholder={"Search"}
                className="flex flex-1 w-full"
                disableUnderline
                // fullWidth
                inputProps={{
                  "aria-label": "Search",
                  className: "inputStyle",
                }}
                value={this.state.searchtext}
                onChange={async (e) => {
                  if (e.target.value) {
                    this.setState({ searchtext: e.target.value });
                  } else {
                    await this.setState({ searchtext: "" });
                    this.getData(defaultPageSize, defaultPage);
                  }
                }}
                onKeyPress={(e) => {
                  if (e && e.key === "Enter") {
                    this.getData(defaultPageSize, defaultPage);
                  }
                }}
              />
              <IconButton
                color="inherit"
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.getData(defaultPageSize, defaultPage);
                }}
              >
                <Icon>search</Icon>
              </IconButton>
            </div>
          </div> */}
          <div className="addButtonStyle" style={{ marginLeft: 8 }}>
            <Button
              size="medium"
              variant="contained"
              className="btnSmall panelAddButton p-0"
              style={{
                maxWidth: 94,
                maxHeight: 34,
                backgroundColor: "#387DFF",
              }}
              onClick={this.handleClickOpen}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                Add User
              </Typography>
            </Button>
          </div>
        </div>
        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={this.state.pages} // Display the total number of pages
          loading={this.props.adminUsersPaginationAndGroupByRootQuery.loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          data={this.state.userList}
          expanderDefaults={{ width: 0 }}
          columns={this.state.columns}
          resizable={true}
          noDataText="No Record Found"
          minRows={1}
          defaultSorted={this.state.sortedArray}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={this.state.pageSize}
          pageSize={this.state.pageSize}
          page={this.state.currentPage}
          onPageChange={(page) => {
            this.setState({ currentPage: page });
          }}
          onPageSizeChange={(sz) => {
            this.setState({
              currentPage: 0,
              pageSize: sz,
            });
          }}
          multiSort={true}
          collapseOnDataChange={false}
          style={{
            maxHeight: height - 65,
            cursor: "default",
          }}
          //   getTrProps={(state, rowInfo, column, instance) => {
          //     return {
          //       onClick: (ev) => {
          //         ev.stopPropagation();
          //         // this.redirectToPlansDetails(rowInfo.original.id);
          //       },
          //     };
          //   }}
        />
        <Dialog
          fullWidth={true}
          scroll={"paper"}
          maxWidth="xs"
          style={{ overflow: "hidden" }}
          disableBackdropClick
          open={this.state.deleteDialog}
          classes={{
            paperFullWidth: classes.paperFullWidth,
          }}
          onClose={() => {
            this.setState({ deleteDialog: false, adminCombId: 0 });
          }}
        >
          <DialogTitle
            className={classes.dialogTitle}
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                Confirm Delete
              </Typography>
              <IconButton
                onClick={() =>
                  this.setState({ deleteDialog: false, adminCombId: 0 })
                }
              >
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent className="p-10">
            <div className="p-16">
              <Typography style={{ fontSize: 18, marginTop: 8 }}>
                Are you sure you want to delete user?
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Button
                onClick={() => {
                  this.setState({ deleteDialog: false, estimateId: 0 });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                // disabled={isSubmitting}
                onClick={() => {
                  this.handleDeleteAdminComb();
                }}
                color="primary"
              >
                Delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          scroll={"paper"}
          maxWidth="xs"
          style={{ overflow: "hidden" }}
          disableBackdropClick
          open={this.state.openDialog}
          onClose={this.handleClose}
          classes={{
            paperFullWidth: classes.paperFullWidth,
          }}
        >
          <DialogTitle
            className={classes.dialogTitle}
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {this.state.titleName}
              </Typography>
              <IconButton onClick={this.handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            classes={{
              root: classes.dialogContentRoot,
            }}
          >
            <UserModal
              edit={this.state.edit}
              onSuccess={(PlanId) => {
                this.props.adminUsersPaginationAndGroupByRootQuery
                  .refetch()
                  .then((i) => {
                    this.setState({
                      userList: this.props
                        .adminUsersPaginationAndGroupByRootQuery
                        .adminUsersPaginationAndGroupBy
                        ? this.props.adminUsersPaginationAndGroupByRootQuery
                            .adminUsersPaginationAndGroupBy.superAdminUsers
                        : [],
                      pages: this.props.adminUsersPaginationAndGroupByRootQuery
                        .adminUsersPaginationAndGroupBy
                        ? Math.ceil(
                            this.props.adminUsersPaginationAndGroupByRootQuery
                              .adminUsersPaginationAndGroupBy.rowCount /
                              defaultPageSize
                          )
                        : 1,
                      pageSize: defaultPageSize,
                      loading: false,
                    });
                  });
              }}
              //   transectionDataVersion={this.state.transectionDataVersion}
              // moduleIds={this.state.moduleList}
              handleClose={this.handleClose}
              //   signupPlanId={this.props.match.params.Id}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({}) => {
  return {};
};

export default withStyles()(
  connect(
    mapStateToProps,
    {}
  )(
    compose(
      graphql(deleteSuperAdminUserMutation, {
        name: "deleteSuperAdminUserMutation",
      }),
      graphql(adminUsersPaginationAndGroupByRootQuery, {
        name: "adminUsersPaginationAndGroupByRootQuery",
        options: (props) => {
          return {
            variables: {
              searchtext: null,
              sortedBy: defaultSorted,
              limit: defaultPageSize,
              offset: defaultPage,
            },
          };
        },
      })
    )(Users)
  )
);
