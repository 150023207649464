import React, { useState, useEffect } from "react";
import { scSubscriptionPricelistBySystemClientIdRootQuery } from "./clientGqlQuery";
import { Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import queryHistory from "../../lib/queryHistroy";
import DraggableTable from "../common/DraggableTable";

const defaultSorted = [
  {
    id: "renewal",
    desc: false,
  },
];
const defaultPageSize = 20;
const defaultPage = 1;
const ClientPriceList = (props) => {
  const {
    data: scSubscriptionPricelistBySystemClientIdData,
    loading: scSubscriptionPricelistBySystemClientIdLoading,
    refetch,
    error: scSubscriptionPricelistBySystemClientIdError,
    fetchMore: scSubscriptionPricelistBySystemClientIdFetchMore,
  } = useQuery(scSubscriptionPricelistBySystemClientIdRootQuery, {
    variables: {
      systemClientId: parseInt(props.systemClientId),
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    // onCompleted: (data) => {
    //   console.log("data", data);
    //   setSubscribedPriceList(
    //     data && data.scSubscriptionPricelistBySystemClientId && data.scSubscriptionPricelistBySystemClientId.scSubscriptionPricelist
    //       .length > 0
    //       ? cloneDeep(
    //           data.scSubscriptionPricelistBySystemClientId.scSubscriptionPricelist
    //         )
    //       : []
    //   );
    //   setPages(
    //     data && data.scSubscriptionPricelistBySystemClientId && data.scSubscriptionPricelistBySystemClientId.scSubscriptionPricelist
    //       ? Math.ceil(
    //           data.scSubscriptionPricelistBySystemClientId.rowCount /
    //             defaultPageSize
    //         )
    //       : 1
    //   );
    // },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      scSubscriptionPricelistBySystemClientIdData &&
      scSubscriptionPricelistBySystemClientIdData.scSubscriptionPricelistBySystemClientId &&
      scSubscriptionPricelistBySystemClientIdData
        .scSubscriptionPricelistBySystemClientId.scSubscriptionPricelist
    ) {
      setSubscribedPriceList(
        scSubscriptionPricelistBySystemClientIdData
          .scSubscriptionPricelistBySystemClientId.scSubscriptionPricelist
      );
      setPages(
        scSubscriptionPricelistBySystemClientIdData
          .scSubscriptionPricelistBySystemClientId.scSubscriptionPricelist
          ? Math.ceil(
              scSubscriptionPricelistBySystemClientIdData
                .scSubscriptionPricelistBySystemClientId.rowCount /
                defaultPageSize
            )
          : 1
      );
    } else {
      setSubscribedPriceList([]);
    }
  }, [scSubscriptionPricelistBySystemClientIdData]);

  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const [subscribedPriceList, setSubscribedPriceList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      HeaderText: "renewal",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Renewal"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "renewal").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "renewal",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {props.original.renewal.charAt(0) +
                props.original.renewal.slice(1).toLowerCase()}{" "}
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "commitment",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Commitment"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "commitment").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "commitment",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {props.original.commitment.charAt(0) +
                props.original.commitment.slice(1).toLowerCase()}
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "price",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Price"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "price").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "price",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>{"$" + props.original.price}</Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
  ];
  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      systemClientId: parseInt(props.systemClientId),
      sortedBy: sortedArray,
      limit: pageSize || 10,
      offset: page || 1,
    };

    let historyFilters = {
      systemClientId: parseInt(props.systemClientId),
      sortedBy: sortedArray,
      limit: pageSize || 10,
      offset: page || 1,
    };
    queryHistory.set(
      props,
      "getSystemClientsPaginationAndGroupByQuery",
      historyFilters
    );
    refetch(newQueryVariables);
  };

  var height = props.height || window.innerHeight - 165;
  return (
    <div style={{ padding: 8 }} className="reactTableWraper">
      <DraggableTable
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        pages={pages} // Display the total number of pages
        loading={scSubscriptionPricelistBySystemClientIdLoading} // Display the loading overlay when we need it
        onFetchData={fetchData} // Request new data when things change
        data={subscribedPriceList}
        expanderDefaults={{ width: 0 }}
        columns={columns}
        resizable={true}
        noDataText="No Record Found"
        minRows={1}
        defaultSorted={sortedArray}
        pageSizeOptions={[20, 25, 50, 100]}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        onPageSizeChange={(sz) => {
          setCurrentPage(0);
          setPageSize(sz);
        }}
        multiSort={true}
        style={{
          maxHeight: height,
          cursor: "default",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps)(ClientPriceList);
