import React, { useState,useEffect } from "react";
import { signupPlanPriceListBySignupPlanIdRootQuery } from "./signupPlansGQLQuery";
import {
  Typography,Dialog,DialogActions,DialogContent,IconButton,DialogTitle,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import queryHistory from "../../lib/queryHistroy";
import CloseIcon from "@material-ui/icons/Close";
import DraggableTable from "../common/DraggableTable";
import ActionOptionsButton from "../common/ActionOptionsButton";
import makeAnimated from "react-select/animated";
import PriceModal from "./PriceModal";

const defaultSorted = [
  {
    id: "renewal",
    desc: false,
  },
];
const defaultPageSize = 20;
const defaultPage = 1;
const PriceList = (props) => {

   const signupPlanPriceListBySignupPlanIdRefetchOnCall=()=>{    
    signupPlanPriceListBySignupPlanIdRefetch()   
  }

  const {
    data: signupPlanPriceListBySignupPlanIdData,
    loading: signupPlanPriceListBySignupPlanIdLoading,
    refetch : signupPlanPriceListBySignupPlanIdRefetch,
    error: signupPlanPriceListBySignupPlanIdError,
    fetchMore: signupPlanPriceListBySignupPlanIdFetchMore,
  } = useQuery(signupPlanPriceListBySignupPlanIdRootQuery, {
    variables: {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    // onCompleted: initializeScreen,
    fetchPolicy: "network-only",
  });


  useEffect(()=>{    
    props.bindSignupPlanPriceListBySignupPlanIdRefetchOnCall(signupPlanPriceListBySignupPlanIdRefetchOnCall)
  },[signupPlanPriceListBySignupPlanIdRefetchOnCall])

  

  useEffect(() => { 
   
    if (
      signupPlanPriceListBySignupPlanIdData &&
      signupPlanPriceListBySignupPlanIdData.signupPlanPriceListBySignupPlanId &&
      signupPlanPriceListBySignupPlanIdData.signupPlanPriceListBySignupPlanId
        .signupPlanPrices
    ) {
      setSignupPlanPriceList(
        signupPlanPriceListBySignupPlanIdData.signupPlanPriceListBySignupPlanId
          .signupPlanPrices
      );
      setPages(
        signupPlanPriceListBySignupPlanIdData.signupPlanPriceListBySignupPlanId
          .signupPlanPrices
          ? Math.ceil(
              signupPlanPriceListBySignupPlanIdData
                .signupPlanPriceListBySignupPlanId.rowCount / defaultPageSize
            )
          : 1
      );
    } else {
      setSignupPlanPriceList([]);
    }
  }, [signupPlanPriceListBySignupPlanIdData]);


  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const [signupPlanPriceList, setSignupPlanPriceList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [id,setId] = useState(0);
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const [openPriceDialog,setOpenPriceDialog] = useState(false);
 const columns= [
    {
      HeaderText: "renewal",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Renewal"}
            {sortedArray &&
              sortedArray.filter(
                (iName) => iName.id === "renewal"
              ).length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "renewal",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {props.original.renewal.charAt(0) +
                props.original.renewal.slice(1).toLowerCase()}{" "}
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "UUID",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"UUID"}                 
          </span>
        </div>
      ),
      id: "uuid",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.uuid}
            </span>
          </>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "commitment",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Commitment"}
            {sortedArray &&
              sortedArray.filter(
                (iName) => iName.id === "commitment"
              ).length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "commitment",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {props.original.commitment.charAt(0) +
                props.original.commitment.slice(1).toLowerCase()}
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "price",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Price"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "price")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "price",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>{"$" + props.original.price}</Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "action",
      Header: (props) => (
        <div style={{ display: "flex" }} className="draggable-header">
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>Action</Typography>
          </span>
        </div>
      ),
      id: "action",
      sortable: false,
      multiSort: false,
      Cell: (rowData) => {
        let options = [];
        options.push({
          label: "Edit",
          callBack: (ev) => {
            // ev.stopPropagation();
            handleEditPriceOpen(rowData.original.id);
          },
        });

        return <ActionOptionsButton options={options} />;
      },
    },
  ];

  
 const handleEditPriceOpen = (id) => {
   setId(id);  
  setOpenPriceDialog(true);    
  };

  const handleClose = () => {
    setOpenPriceDialog(false);
  };

  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: sortedArray,
      limit: pageSize || 20,
      offset: page || 1,
    };

    let historyFilters = {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: sortedArray,
      limit: pageSize || 20,
      offset: page || 1,
    };
    queryHistory.set(
      props,
      "getSystemClientsPaginationAndGroupByQuery",
      historyFilters
    );
    signupPlanPriceListBySignupPlanIdRefetch(newQueryVariables);
  };
  const { classes } = props;
   var height = props.height || window.innerHeight - 165;
  return (
    <div style={{ padding: 8 }} className="reactTableWraper">
      <DraggableTable
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        pages={pages} // Display the total number of pages
        loading={signupPlanPriceListBySignupPlanIdLoading} // Display the loading overlay when we need it
        onFetchData={fetchData} // Request new data when things change
        data={signupPlanPriceList}
        expanderDefaults={{ width: 0 }}
        columns={columns}
        resizable={true}
        noDataText="No Record Found"
        minRows={1}
        defaultSorted={sortedArray}
        pageSizeOptions={[20, 25, 50, 100]}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        onPageSizeChange={(sz) => {
          setCurrentPage(0);
          setPageSize(sz);
        }}
        multiSort={true}
        style={{
          maxHeight: height,          
          cursor: "default",
        }}      
      />
       <Dialog
          fullWidth={true}
          scroll={"paper"}
          maxWidth="xs"
          style={{ overflow: "hidden" }}
          disableBackdropClick
          open={openPriceDialog}
          onClose={handleClose}
        >
          <DialogTitle
            // className={classes.dialogTitle}
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                Edit Price
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <PriceModal
              onSuccess={() => {
                signupPlanPriceListBySignupPlanIdRefetch();
                // this.props.signupPlanPriceListBySignupPlanIdRootQuery
                //   .refetch()
                //   .then((i) => {
                //     this.setState({
                //       signupPlanPriceList: !this.props
                //         .signupPlanPriceListBySignupPlanIdRootQuery.loading
                //         ? this.props.signupPlanPriceListBySignupPlanIdRootQuery
                //             .signupPlanPriceListBySignupPlanId.signupPlanPrices
                //         : [],
                //       pages: this.props
                //         .signupPlanPriceListBySignupPlanIdRootQuery
                //         .signupPlanPriceListBySignupPlanId
                //         ? Math.ceil(
                //             this.props
                //               .signupPlanPriceListBySignupPlanIdRootQuery
                //               .signupPlanPriceListBySignupPlanId.rowCount /
                //               defaultPageSize
                //           )
                //         : 1,
                //       pageSize: defaultPageSize,
                //       loading: false,
                //     });
                //   });
              }}
              edit={true}
              priceId={id}              
              handleClose={handleClose}
              signupPlanId={props.signupPlanId}
            />
          </DialogContent>
        </Dialog>
    </div>
  );
};

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps)(PriceList);
