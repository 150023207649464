import React, { useState,useEffect } from "react";
import { systemClientByPlanIdRootQuery } from "../clients/clientGqlQuery";
import {
  Typography,Dialog,DialogActions,DialogContent,IconButton,DialogTitle,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import queryHistory from "../../lib/queryHistroy";
import CloseIcon from "@material-ui/icons/Close";
import DraggableTable from "../common/DraggableTable";
import ActionOptionsButton from "../common/ActionOptionsButton";
import makeAnimated from "react-select/animated";
import moment from "moment-timezone";

const defaultSorted = [
  {
    id: "clientName",
    desc: false,
  },
];

const defaultPageSize = 20;
const defaultPage = 1;
const ClientList = (props) => {

   const systemClientByPlanIdRefetchOnCall=()=>{    
    systemClientByPlanIdRefetch()   
  }

  const {
    data: systemClientByPlanIdData,
    loading: systemClientByPlanIdLoading,
    refetch : systemClientByPlanIdRefetch,
    error: systemClientByPlanIdError,
    fetchMore: systemClientByPlanIdFetchMore,
  } = useQuery(systemClientByPlanIdRootQuery, {
    variables: {
      planId: parseInt(props.planId),
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    fetchPolicy: "network-only",
  });


  useEffect(()=>{    
    props.bindSystemClientByPlanIdRefetchOnCall(systemClientByPlanIdRefetchOnCall)
  },[systemClientByPlanIdRefetchOnCall])

  useEffect(() => {
    if (
      systemClientByPlanIdData &&
      systemClientByPlanIdData.systemClientByPlanId &&
      systemClientByPlanIdData.systemClientByPlanId
        .clientList
    ) {
      setClientList(
        systemClientByPlanIdData.systemClientByPlanId
          .clientList
      );
      setPages(
        systemClientByPlanIdData.systemClientByPlanId
          .clientList
          ? Math.ceil(
              systemClientByPlanIdData
                .systemClientByPlanId.rowCount / defaultPageSize
            )
          : 1
      );
    } else {
      setClientList([]);
    }
  }, [systemClientByPlanIdData]);


  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const [clientList, setClientList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [id,setId] = useState(0);
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const [openPriceDialog,setOpenPriceDialog] = useState(false);
 const columns= [
    {
      HeaderText: "clientName",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Client Name"}
            {sortedArray &&
              sortedArray.filter(
                (iName) => iName.id === "clientName"
              ).length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "clientName",
      Cell: (props) => {        
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {props.original.clientName.charAt(0) +
                props.original.clientName.slice(1).toLowerCase()}
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "customerStatus",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Status"}
            {sortedArray &&
              sortedArray.filter(
                (iName) => iName.id === "customerStatus"
              ).length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "customerStatus",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {props.original.customerStatus.charAt(0) +
                props.original.customerStatus.slice(1).toLowerCase()}
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "price",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Price"}
            {sortedArray &&
              sortedArray.filter(
                (iName) => iName.id === "price"
              ).length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "price",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.price > 0
                ? "$ " + props.original.price
                : "-"}
            </span>
          </>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Trial Start Date",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Trial Start Date"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "trialStartDate")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "trialStartDate",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.trialStartDate ? moment(props.original.trialStartDate)
                .tz("America/New_York")
                .format("MM-DD-YYYY") : ""}
            </span>
          </>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Trial End Date",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Trial End Date"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "trialEndDate")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "trialEndDate",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.trialEndDate ? moment(props.original.trialEndDate)
                .tz("America/New_York")
                .format("MM-DD-YYYY") : ""}
            </span>
          </>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Subscription Start Date",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Subscription Start Date"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "subscriptionStartDate")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "subscriptionStartDate",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.subscriptionStartDate ? moment(props.original.subscriptionStartDate)
                .tz("America/New_York")
                .format("MM-DD-YYYY") : ""}
            </span>
          </>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Subscription Expiry Date",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Subscription Expiry Date"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "subscriptionExpiryDate")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "subscriptionExpiryDate",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.subscriptionExpiryDate ? moment(props.original.subscriptionExpiryDate)
                .tz("America/New_York")
                .format("MM-DD-YYYY") : ""}
            </span>
          </>
        );
      },
      sortable: true,
      multiSort: true,
    },
  ];

  
 const handleEditPriceOpen = (id) => {
   setId(id);  
  setOpenPriceDialog(true);    
  };

  const handleClose = () => {
    setOpenPriceDialog(false);
  };

  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      planId: parseInt(props.planId),
      sortedBy: sortedArray,
      limit: pageSize || 20,
      offset: page || 1,
    };

    let historyFilters = {
      planId: parseInt(props.planId),
      sortedBy: sortedArray,
      limit: pageSize || 20,
      offset: page || 1,
    };
    queryHistory.set(
      props,
      "getSystemClientsPaginationAndGroupByQuery",
      historyFilters
    );
    systemClientByPlanIdRefetch(newQueryVariables);
  };
  const { classes } = props;
   var height = props.height || window.innerHeight - 165;
  return (
    <div style={{ padding: 8 }} className="reactTableWraper">
      <DraggableTable
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        pages={pages} // Display the total number of pages
        loading={systemClientByPlanIdLoading} // Display the loading overlay when we need it
        onFetchData={fetchData} // Request new data when things change
        data={clientList}
        expanderDefaults={{ width: 0 }}
        columns={columns}
        resizable={true}
        noDataText="No Record Found"
        minRows={1}
        defaultSorted={sortedArray}
        pageSizeOptions={[20, 25, 50, 100]}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        onPageSizeChange={(sz) => {
          setCurrentPage(0);
          setPageSize(sz);
        }}
        multiSort={true}
        style={{
          maxHeight: height,
          cursor: "default",
        }}
      />

       {/* <Dialog
          fullWidth={true}
          scroll={"paper"}
          maxWidth="xs"
          style={{ overflow: "hidden" }}
          disableBackdropClick
          open={openPriceDialog}
          onClose={handleClose}
        >
          <DialogTitle
            // className={classes.dialogTitle}
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                Edit Price
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <PriceModal
              onSuccess={() => {
                systemClientByPlanIdRefetch();                
              }}
              edit={true}
              priceId={id}              
              handleClose={handleClose}
              planId={props.planId}
            />
          </DialogContent>
        </Dialog> */}
    </div>
  );
};

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps)(ClientList);
