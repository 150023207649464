import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  InputLabel,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { addCarrierServiceMutation, editCarrierServiceMutation } from "./CarrierServiceGqlQuery";
import { useFormik } from "formik";
import Spinner from "../common/Spinner";

const CarrierServiceModal = (props) => {
    const { classes } = props;
   
    let initFormInfo = {
        id: 0,
        carrier: "FedEx",
        serviceName: "",
        serviceCode: "",
        orderServiceName: "",
        orderServiceCode: "",
      };
    
      const [initFormData, setinitFormData] = useState(initFormInfo);
    
    let [duplicateServiceNameErr, setDuplicateServiceNameErr] = useState("");
    let [duplicateServiceCodeErr, setDuplicateServiceCodeErr] = useState("");
    let [duplicateOrderServiceNameErr, setDuplicateOrderServiceNameErr] = useState("");
    let [duplicateOrderServiceCodeErr, setDuplicateOrderServiceCodeErr] = useState("");
    let [loader, setLoader] = useState(false);
    
    const [addCarrierServices] = useMutation(addCarrierServiceMutation);
    const [editCarrierServices] = useMutation(editCarrierServiceMutation);
  
 
    useEffect(()=> {
        if(props.edit) {
            let cData = props.carrierServiceData;
            setinitFormData(cData);
            formik.setValues({
                ...cData,
            });
        }
    }, []);
  
  const formik = useFormik({
    initialValues: initFormData,
    validateOnBlur: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      serviceName: Yup.string()
        .min(1, "Service name must be atleast 1 character")
        .trim()
        .max(200, "Service name accept up to 200 characters")
        .required("Please enter service name"),
      serviceCode: Yup.string()
        .min(1, "Service code must be atleast 1 character")
        .trim()
        .max(100, "Service code accept up to 100 characters")
        .required("Please enter service code"),
      orderServiceName: Yup.string()
        .min(1, "Order service name must be atleast 1 character")
        .trim()
        .max(200, "Order service name accept up to 200 characters")
        .required("Please enter order service name"),
      orderServiceCode: Yup.string()
        .min(1, "Order service code must be atleast 1 character")
        .trim()
        .max(100, "Order service code accept up to 100 characters")
        .required("Please enter order service code")
    }),
  });

  


  const handleSubmit = (values) => {
    
    let cData = {
      id: values.id,
      carrier: values.carrier,
      serviceName: values.serviceName.trim(),
      serviceCode: values.serviceCode.trim(),
      orderServiceName: values.orderServiceName.trim(),
      orderServiceCode: values.orderServiceCode.trim(),
    };
    setLoader(true);
    setDuplicateServiceNameErr("");
    setDuplicateServiceCodeErr("");
    setDuplicateOrderServiceNameErr("");
    setDuplicateOrderServiceCodeErr("");

    if (values.id > 0) {
      
      editCarrierServices({
        variables: {
          carrierServiceData: cData
        },
      })
        .then((data) => {
          if (data && data.data && data.data.editCarrierService) {
            if(props.onSuccess) {
                props.onSuccess();
            }
            props.handleClose();
          }
          
          setLoader(false);
          
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
          if (error && error.graphQLErrors && error.graphQLErrors[0] ) {
            const message = error.graphQLErrors[0].message;
            hdlDuplicateErrors(message);
          }
        });
    } else {
      addCarrierServices({
        variables: {
          carrierServiceData: cData
        },
      })
        .then((data) => {
          if (
            data &&
            data.data &&
            data.data.addCarrierService &&
            data.data.addCarrierService.id
          ) {
            if(props.onSuccess) {
                props.onSuccess();
            }
            props.handleClose();
          }
          
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
          if (error && error.graphQLErrors && error.graphQLErrors[0] ) {
            const message = error.graphQLErrors[0].message;
            hdlDuplicateErrors(message);
          }
        });
    }
  };

  const hdlDuplicateErrors = (msg) => {
    let serviceNameErr = "Service name already exists";
    let serviceCodeErr = "Service code already exists";
    let ordServiceNameErr = "Order service name already exists";
    let ordServiceCodeErr = "Order service code already exists";

    if(msg === "DUPLICATE_ALL") {
      setDuplicateServiceNameErr(serviceNameErr);
      setDuplicateServiceCodeErr(serviceCodeErr);
      setDuplicateOrderServiceNameErr(ordServiceNameErr);
      setDuplicateOrderServiceCodeErr(ordServiceCodeErr);
    }
    if (msg === "DUPLICATE") {
      setDuplicateServiceNameErr(serviceNameErr);
      setDuplicateServiceCodeErr(serviceCodeErr);
    } else if (msg === "SERVICE_NAME") {
      setDuplicateServiceNameErr(serviceNameErr);
    } else if (msg === "SERVICE_CODE") {
      setDuplicateServiceCodeErr(serviceCodeErr);
    }
    if (msg === "DUPLICATE_ORDER_SERVICE") {
      setDuplicateOrderServiceNameErr(ordServiceNameErr);
      setDuplicateOrderServiceCodeErr(ordServiceCodeErr);
    } else if (msg === "ORDER_SERVICE_NAME") {
      setDuplicateOrderServiceNameErr(ordServiceNameErr);
    } else if (msg === "ORDER_SERVICE_CODE") {
      setDuplicateOrderServiceCodeErr(ordServiceCodeErr);
    }
  }

  const hdlClose = () => {
    setinitFormData(initFormInfo);
    formik.resetForm();
    props.handleClose();
  }

  return (
    <form onSubmit={formik.handleSubmit}>
          {loader && <Spinner />}
      <DialogContent style={{ overflow: "hidden", paddingTop: 0 }}>
        <Grid container spacing={2} style={{ marginTop: 14 }}>
            
            <Grid item xs={12}>

                <FormControl
                                  variant="outlined"
                                  className="w-full"
                                  size="small"
                                >
                                  <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    className={classes.lableOutline}
                                  >
                                    {"Carrier"}
                                   </InputLabel>
                                  <Select
                                    value={formik.values.carrier}
                                    onChange={(evt) => {
                                      formik.setFieldValue("carrier", evt.target.value);
                                    }}
                                    className="w-full"
                                  >
                                    <MenuItem value={"FedEx"}>
                                      {"FedEx"}
                                    </MenuItem>
                                    <MenuItem value={"UPS"}>
                                      {"UPS"}
                                    </MenuItem>
                                    <MenuItem value={"Inhouse"}>
                                      {"Inhouse"}
                                    </MenuItem>
                                  </Select>
                                </FormControl>

            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                variant="outlined"
                id="serviceName"
                size="small"
                label="Service name"
                type="text"
                fullWidth
                value={formik.values.serviceName}
                name="serviceName"
                onChange={formik.handleChange}
                required
                helperText={
                  (formik.errors.serviceName && formik.touched.serviceName
                    && formik.errors.serviceName) || duplicateServiceNameErr
                    
                }
                error={
                  (formik.touched.serviceName && formik.errors.serviceName) || duplicateServiceNameErr
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                id="serviceCode"
                label="Service code"
                type="text"
                fullWidth
                value={formik.values.serviceCode}
                name="serviceCode"
                onChange={formik.handleChange}
                required
                helperText={
                  (formik.errors.serviceCode && formik.touched.serviceCode
                    && formik.errors.serviceCode) || duplicateServiceCodeErr
                    
                }
                error={
                  (formik.touched.serviceCode && formik.errors.serviceCode) || duplicateServiceCodeErr
                }
              />
            </Grid>

            <Grid item xs={12}>
               <TextField
                variant="outlined"
                id="orderServiceName"
                size="small"
                label="Order service name"
                type="text"
                fullWidth
                value={formik.values.orderServiceName}
                name="orderServiceName"
                onChange={formik.handleChange}
                required
                helperText={
                  (formik.errors.orderServiceName && formik.touched.orderServiceName
                    && formik.errors.orderServiceName) || duplicateOrderServiceNameErr
                    
                }
                error={
                  (formik.touched.orderServiceName && formik.errors.orderServiceName) || duplicateOrderServiceNameErr
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                id="orderServiceCode"
                label="Order service code"
                type="text"
                fullWidth
                value={formik.values.orderServiceCode}
                name="orderServiceCode"
                onChange={formik.handleChange}
                required
                helperText={
                  (formik.errors.orderServiceCode && formik.touched.orderServiceCode
                    && formik.errors.orderServiceCode) || duplicateOrderServiceCodeErr
                    
                }
                error={
                  (formik.touched.orderServiceCode && formik.errors.orderServiceCode) || duplicateOrderServiceCodeErr
                }
              />
            </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{
        display: "flex", justifyContent: props.edit ? "space-between" : "flex-end",
        alignItems: "baseline"
      }}>
            {props.edit && 
            <Typography style={{
                color: "red",
                fontSize: 14,
                marginLeft: 10
            }}>
                    {"Order service name/code will not update in mappings"}
                </Typography>
            }
            <div>  
                <Button onClick={hdlClose} color="primary">
                    {"Cancel"}
                </Button>
                <Button
                    style={{ marginLeft: 4 }}
                    onClick={formik.handleSubmit}
                    color="primary"
                >
                    {"Save"}
                </Button>
            </div>
      </DialogActions>
    </form>
  );
};


const mapStateToProps = () => {
  return {};
};

const styles = (theme) => ({
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px",
  },
});

export default withStyles(styles)(connect(mapStateToProps)(CarrierServiceModal));
