import gql from "graphql-tag";

export const getCarrierServicesByFilterPaginationQuery = gql`
  query getCarrierServicesByFilterPaginationQuery($searchText: String, $filtersData: CarrierServiceFilterDataType, $sortedBy: [CarrierServiceSortedByType], $limit: Int, $offset: Int) {
    carrierServicesByFilterPagination(searchText: $searchText, filtersData: $filtersData, sortedBy: $sortedBy, limit: $limit, offset: $offset) {
      carrierServices {
        id
        carrier
        serviceName
        serviceCode
        orderServiceName
        orderServiceCode
      }
      rowCount
    }
  }
`;

export const addCarrierServiceMutation = gql`
    mutation addCarrierService($carrierServiceData: CarrierServiceInputType) {
        addCarrierService(carrierServiceData: $carrierServiceData) {
            id
        }
    }
`;

export const editCarrierServiceMutation = gql`
    mutation editCarrierService($carrierServiceData: CarrierServiceInputType) {
        editCarrierService(carrierServiceData: $carrierServiceData) {
            id
        }
    }
`;