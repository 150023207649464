import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControlLabel,
  MenuItem,
  InputLabel,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  FormHelperText,
  Button,
  Radio,
  RadioGroup,
  Checkbox,
  FormControl,
  Select as MUISelect,
} from "@material-ui/core";
// import { DateTimePicker } from "@material-ui/pickers";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import {
//   editPriceToSignuPlanPricelistMutation,
//   addPriceToSignuPlanPricelistMutation,
//   priceDetailsByIdRootQuery,
// } from "./signupPlansGQLQuery";
import {
  addSbizzlMaintenanceMutation,
  editSbizzlMaintenanceMutation,
  updateSbizzlMaintenanceCompletedMutation,
} from "./sbizzlMaintenanceGqlQuery";
import AmountInput from "../common/AmountInput";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";

const AddEditMaintenanceModal = (props) => {
  //   const {
  //     data: priceDetailsByIdData,
  //     loading: priceDetailsByIdLoading,
  //     refetch: priceDetailsByIdRefetch,
  //     error: priceDetailsByIdError,
  //     fetchMore: priceDetailsByIdFetchMore,
  //   } = useQuery(priceDetailsByIdRootQuery, {
  //     variables: {
  //       priceId: parseInt(props.priceId) || null,
  //     },
  //     onCompleted: (data) => {
  //       if (
  //         priceDetailsByIdData &&
  //         priceDetailsByIdData.priceDetailsById &&
  //         priceDetailsByIdData.priceDetailsById.id !== null
  //       ) {
  //         setInitForm({
  //           id: priceDetailsByIdData.priceDetailsById.id,
  //           renewal: priceDetailsByIdData.priceDetailsById.renewal,
  //           price: priceDetailsByIdData.priceDetailsById.price,
  //           commitment: priceDetailsByIdData.priceDetailsById.commitment,
  //         });
  //       }
  //     },
  //     fetchPolicy: "network-only",
  //   });
  const [addSbizzlMaintenance] = useMutation(addSbizzlMaintenanceMutation);
  const [editSbizzlMaintenance] = useMutation(editSbizzlMaintenanceMutation);
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [selectedModule, setSelectedModule] = useState({});
  const [moduleId, setModuleId] = useState(null);
  const [endDateOutlineLable, setEndDateOutlineLable] = useState(false);
  const [duplicateMsg, setDuplicateMsg] = useState("");
  const [startDateOutlineLable, setStartDateOutlineLable] = useState(false);
  const [initForm, setInitForm] = useState({
    id: 0,
    fromDatetime: null,
    toDatetime: null,
    isCompleted: false,
    isActive: false,
    note: "",
  });

  useEffect(() => {
    if (props.maintenanceObj.id > 0) {
      setInitForm({
        id: props.maintenanceObj.id,
        fromDatetime: new Date(props.maintenanceObj.fromDatetime),
        toDatetime: new Date(props.maintenanceObj.toDatetime),
        isCompleted: props.maintenanceObj.isCompleted,
        isActive: props.maintenanceObj.isActive,
        note: props.maintenanceObj.note,
      });
    }
  }, []);
  const formik = useFormik({
    initialValues: initForm,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      fromDatetime: Yup.date()
        .nullable()
        .max(Yup.ref("toDatetime"), "Start time cannot be after the end time")
        .required("Please select start time"),
      toDatetime: Yup.date()
        .nullable()
        .min(Yup.ref("fromDatetime"), "Start time cannot be after the end time")
        .required("Please select end time"),
      isCompleted: Yup.boolean(),
      isActive: Yup.boolean(),
      note: Yup.string(),
    }),
  });

  const handleSubmit = (values) => {
    let sbizzlMaintenance = {};
    sbizzlMaintenance.id = values.id;
    sbizzlMaintenance.fromDatetime = moment(values.fromDatetime)
      .tz("UTC")
      .format("YYYY-MM-DD HH:mm:ss");
    sbizzlMaintenance.toDatetime = moment(values.toDatetime)
      .tz("UTC")
      .format("YYYY-MM-DD HH:mm:ss");
    sbizzlMaintenance.isCompleted = values.isCompleted;
    sbizzlMaintenance.isActive = values.isActive;
    sbizzlMaintenance.note = values.note;
    // console.log("values and obejcts",values,sbizzlMaintenance)
    if (values.id > 0) {
      editSbizzlMaintenance({
        variables: {
          sbizzlMaintenance,
        },
      })
        .then((item) => {
          if (props.onSuccess) {
            props.onSuccess(item);
          }
          props.handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      addSbizzlMaintenance({
        variables: {
          sbizzlMaintenance,
        },
      })
        .then((item) => {
          if (props.onSuccess) {
            props.onSuccess();
          }
          props.handleClose();
        })
        .catch((err) => {
          props.handleClose();
          console.log(err);
        });
    }
  };

  const { classes } = props;
  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
        style={{ background: "#fff" }}
      >
        <div style={{ width: "320px" }}>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.rowContainer}
          >
            <div className="fullWidthDateTime dateComponent fromDate">
              <DatePicker
                selected={formik.values.fromDatetime}
                popperPlacement="top-bottom"
                onChange={(fromDatetime) => {
                  formik.setFieldValue("fromDatetime", fromDatetime);
                }}
                error={
                  formik.touched.fromDatetime && formik.errors.fromDatetime
                    ? true
                    : false
                }
                // openToDate={new Date()}
                helperText={
                  formik.errors.fromDatetime &&
                  formik.touched.fromDatetime &&
                  formik.errors.fromDatetime
                }
                placeholderText={"Start Date"}
                dateFormat="MMMM d, yyyy h:mm aa"
                className={
                  formik.errors.fromDatetime
                    ? "datePickerComponent errorDisplay"
                    : "datePickerComponent"
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={(e) => {
                  if (e) {
                    e.target.select();
                    setStartDateOutlineLable(true);
                    // this.setState({ startDateOutlineLable: true });
                  }
                }}
                onClickOutside={() => {
                  setStartDateOutlineLable(false);
                  //   this.setState({ startDateOutlineLable: false });
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
              />
              <span
                className={
                  startDateOutlineLable || props.pickerStartDateTime
                    ? "dateInputLable outLineDateInputLable"
                    : "dateInputLable"
                }
              >
                {"Start Date"}
              </span>
              {formik.errors.fromDatetime === "Please select start time" && (
                <span
                  style={{
                    color: "red",
                    display: "block",
                    fontSize: 13,
                    marginTop: 3,
                  }}
                >
                  {formik.errors.fromDatetime}
                </span>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.rowContainer}
          >
            <div className="fullWidthDateTime dateComponent fromDate">
              <DatePicker
                selected={formik.values.toDatetime}
                onChange={(toDatetime) => {
                  formik.setFieldValue("toDatetime", toDatetime);
                }}
                error={
                  formik.touched.toDatetime && formik.errors.toDatetime
                    ? true
                    : false
                }
                // openToDate={new Date()}
                helperText={
                  formik.errors.toDatetime &&
                  formik.touched.toDatetime &&
                  formik.errors.toDatetime
                }
                popperPlacement="top-bottom"
                dateFormat="MMMM d, yyyy h:mm aa"
                className={
                  formik.errors.toDatetime
                    ? "datePickerComponent errorDisplay"
                    : "datePickerComponent"
                }
                placeholderText={"To Date"}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={(e) => {
                  if (e) {
                    e.target.select();
                    setEndDateOutlineLable(true);
                    // this.setState({ endDateOutlineLable: true });
                  }
                }}
                onClickOutside={() => {
                  setEndDateOutlineLable(false);
                  //   this.setState({ endDateOutlineLable: false });
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                // minDate={values.fromDatetime}
              />
              <span
                className={
                  endDateOutlineLable || props.pickerEndDateTime
                    ? "dateInputLable outLineDateInputLable"
                    : "dateInputLable"
                }
              >
                {"To Date"}
              </span>
              {formik.errors.toDatetime && (
                <span
                  style={{
                    color: "red",
                    display: "block",
                    fontSize: 13,
                    marginTop: 3,
                  }}
                >
                  {formik.errors.toDatetime}
                </span>
              )}
            </div>
          </Grid>

          <Grid
            container
            item
            xs={12}
            style={{ marginLeft: 18 }}
            spacing={3}
            className={classes.rowContainer}
          >
            <FormControlLabel
              className="chekBoxLabelActive"
              control={
                <Checkbox
                  name="Active"
                  checked={formik.values.isActive}
                  onChange={(e) => {
                    formik.setFieldValue("isActive", e.target.checked);
                  }}
                  color="primary"
                  className="checkBoxprimary"
                />
              }
              label={"Active"}
            />
          </Grid>

          {/* <Grid container item xs={12} spacing={3} className={classes.rowContainer}>
                        <FormControlLabel
                          className="chekBoxLabelActive"
                          control={
                            <Checkbox
                              name="isCompleted"
                              checked={values.isCompleted}
                              onChange={(e) => {
                                setFieldValue("isCompleted", e.target.checked );
                              }}
                              color="primary"
                              className="checkBoxprimary"
                            />
                          }
                          label={"Completed"}
                        />
                      </Grid> */}

          <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.rowContainer}
          >
            <TextareaAutosize
              aria-label="note"
              rowsMin={5}
              className="textareaStyle"
              placeholder="note"
              onChange={(event) => {
                if (event && event.target) {
                  formik.setFieldValue("note", event.target.value);
                }
              }}
              value={formik.values.note}
            />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              formik.handleSubmit();
            }}
            color="primary"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </form>
  );
};

AddEditMaintenanceModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

const styles = (theme) => ({
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  rowContainer: {
    margin: "0px 0px 15px 0px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  table: {
    minWidth: 700,
  },
  textareaAutosize: {
    width: "100%",
    padding: "5px 10px",
    resize: "none",
  },
  textField: {
    width: "100%",
  },
  panel: {
    position: "fixed",
    width: 360,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    top: 0,
    height: "100%",
    minHeight: "100%",
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    padding: "55px 0px 0px",
    transform: "translate3d(360px,0,0)",
    // overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      transform: "translate3d(360px,0,0)",
      boxShadow: "none",
      "&.opened": {
        boxShadow: theme.shadows[5],
      },
    },
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    "&.opened": {
      transform: "translateX(0)",
    },
  },
});

export default withStyles(styles)(
  connect(mapStateToProps)(AddEditMaintenanceModal)
);
