import React, { useState, useEffect } from "react";
import { getSystemClientsPaginationAndGroupByQuery } from "./clientGqlQuery";
import {
  Typography,
  Popover,
  Chip,
  FormControl,
  Select as MUISelect,
  Button,
  Grid,
  Input,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import Select from "react-select";
import { useQuery } from "@apollo/client";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import queryHistory from "../../lib/queryHistroy";
import DraggableTable from "../common/DraggableTable";
import { industryQuery } from "../plans/signupPlansGQLQuery";

const defaultSorted = [
  {
    id: "client_name",
    desc: false,
  },
];
const defaultPageSize = 20;
const defaultPage = 1;
const Clients = (props) => {
  let historyVars =
  queryHistory.get(props, "getSystemClientsPaginationAndGroupByQuery") || {};

  const industryListQuery = useQuery(industryQuery, {
    fetchPolicy: "network-only",
  });
  let historyObj = {
    sortedBy: historyVars.sortedBy || defaultSorted,
    limit:historyVars.limit ? historyVars.limit :defaultPageSize,
    offset:historyVars.offset ? historyVars.offset : defaultPage,
    searchtext: historyVars.searchtext || "",
    filterData: {
      ...historyVars.filtersData,
      industryId:
        historyVars &&
        historyVars.filterData &&
        historyVars.filterData.industryId
          ? historyVars.filterData.industryId
          : null,
      status:
        historyVars && historyVars.filterData && historyVars.filterData.status
          ? historyVars.filterData.status.map((i) => i.value)
          : ["ACTIVE", "TRIAL"],          
    },
  };

  const {
    data: systemClientQueryData,
    loading: systemClientQueryLoading,
    refetch,
    error: systemClientQueryError,
    fetchMore: systemClientQueryFetchMore,
  } = useQuery(getSystemClientsPaginationAndGroupByQuery, {
    variables: historyObj
      ? historyObj
      : {
          searchtext: null,
          filterData: {
            status: ["ACTIVE", "TRIAL"],
            industryId:
              props &&
              props.location &&
              props.location.state &&
              props.location.state.industryId
                ? props.location.state.industryId
                : null,
          },
          sortedBy: defaultSorted,
          limit: defaultPageSize,
          offset: defaultPage,
        },
        // onCompleted:(data)=>{
        //   setSystemClientList(data.systemClientsPaginationAndGroupBy.systemClients);
        //       setPages(
        //         data.systemClientsPaginationAndGroupBy
        //           ? Math.ceil(
        //               data.systemClientsPaginationAndGroupBy.rowCount / defaultPageSize
        //             )
        //           : 1
        //       );
        // },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      systemClientQueryData &&
      systemClientQueryData.systemClientsPaginationAndGroupBy &&
      systemClientQueryData.systemClientsPaginationAndGroupBy.systemClients
    ) {
      setSystemClientList(systemClientQueryData.systemClientsPaginationAndGroupBy.systemClients);
      setPages(
        systemClientQueryData.systemClientsPaginationAndGroupBy.systemClients
          ? Math.ceil(
              systemClientQueryData.systemClientsPaginationAndGroupBy.rowCount / defaultPageSize
            )
          : 1
      );
    } else {
      setSystemClientList([]);
    }
  }, [systemClientQueryData]);

  const columns = [
    {
      HeaderText: "Company",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Company"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "client_name").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "client_name",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>{props.original.clientName}</Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Owner name",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">{"Owner name"}</span>
        </div>
      ),
      sortable: false,
      multiSort: false,
      Cell: (props) => {
        return (
          <span>
            {props.original.ownerName ? props.original.ownerName : "" }
          </span>
        );
      },
    },
    {
      HeaderText: "Email",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">{"Email"}</span>
        </div>
      ),
      id: "email",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>{props.original.email}</Typography>
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Phone",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">{"Phone"}</span>
        </div>
      ),
      sortable: false,
      multiSort: false,
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize" >
            <Typography>{props.original.phoneNo}</Typography>
          </div>
        );
      }
    },
    {
      HeaderText: "Price",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">{"Price"}</span>
        </div>
      ),
      id: "price",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>{"$ " + props.original.price}</Typography>
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Type",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Type"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "industry_name")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "industry_name",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>{props.original.industryName}</Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Plan Name",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Plan Name"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "plan_name").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "plan_name",
      Cell: (props) => {        
        return (
          <div className="flex items-center capitalize">
            <Typography>{props.original.planName}</Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Billing Frequency",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">
            {"Billing Frequency"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "billing_frq").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "billing_frq",
      Cell: (props) => {
        let renewalString = props.original.renewal
          ? props.original.renewal.charAt(0) +
            props.original.renewal.slice(1).toLowerCase()
          : "";
        return (
          <div className="flex items-center capitalize">
            <Typography>{renewalString.toString()}</Typography>
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Next Billing Date",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">
            {"Next Billing Date"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "renewal_date")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "renewal_date",
      Cell: (props) => {
        let endDate = props.original.subscriptionExpiryDate
          ? moment(props.original.subscriptionExpiryDate)
              .add(1, "d")
              .format("MM/DD/YYYY")
          : "-";
        let startDate = props.original.subscriptionStartDate
          ? moment(props.original.subscriptionStartDate).format("MM/DD/YYYY")
          : "-";
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {props.original.subscriptionExpiryDate
                ? endDate.toString()
                : startDate.toString()}
            </Typography>
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Status",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">
            {"Status"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "status").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "status",
      Cell: (props) => {
        let statusColor =
          props.original.customerStatus === "TRIAL"
            ? "#34c0eb"
            : props.original.customerStatus === "SUSPENDED"
            ? "grey"
            : props.original.customerStatus === "DELETED"
            ? "#fa1100"
            : props.original.customerStatus === "EXPIRED"
            ? "#FF0000"
            : props.original.customerStatus === "CANCELLED"
            ? "#FFA500"
            : props.original.customerStatus === "ACTIVE"
            ? "green"
            : "";
        return (
          <div className="flex items-center capitalize">
            <Typography style={{ color: statusColor }}>
              {props.original.customerStatus
                ? props.original.customerStatus === "TRIAL"
                  ? props.original.customerStatus.charAt(0) +
                    props.original.customerStatus.slice(1).toLowerCase() +
                    (props.original.trialEndDate
                      ? " - " +
                        parseInt(moment(props.original.trialEndDate, "YYYY-MM-DD").diff(
                          moment().format("YYYY-MM-DD"),
                          "days"
                        ) + 1)
                      : "") +
                    " Days left"
                  : props.original.customerStatus.charAt(0) +
                    props.original.customerStatus.slice(1).toLowerCase()
                : ""}
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Sign Up Date",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">
            {"Sign Up Date"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "sign_up_date")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "sign_up_date",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {moment(props.original.createdDatetime).tz("America/New_York").format("MM/DD/YYYY HH:mm:ss")
                }
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Last Login Date",
      Header: (props) => (
        <div className="draggable-header" style={{ cursor: "default" }}>
          <span className="reactTable-headerText">
            {"Last Login Date"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "last_login_date")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "last_login_date",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            <Typography>
              {props.original.lastLoginDate ? 
              moment(props.original.lastLoginDate).tz("America/New_York").format("MM/DD/YYYY HH:mm:ss") : ""
                }
            </Typography>
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
  ];
  const divRef = React.useRef();
 

  const [sortedArray, setSortedArray] = useState(defaultSorted);
  let [anchorEl, setAnchorEl] = React.useState(null);
  const [industryList, setIndustryList] = useState(null);
  const [filterState, setFilterState] = useState(false);
  let [search, setSearch] = useState(
    historyVars && historyVars.searchtext ? historyVars.searchtext : ""
  );
  const [systemClientList, setSystemClientList] = useState([]);
  let [industryId, setIndusryId] = useState(
    historyVars && historyVars.filterData && historyVars.filterData.industryId
      ? historyVars.filterData.industryId
      : props &&
        props.location &&
        props.location.state &&
        props.location.state.industryId
      ? props.location.state.industryId
      : 0
  );
  let [status, setStatus] = useState(
    historyVars && historyVars.filterData && historyVars.filterData.status
      ? historyVars.filterData.status
      : [
          { label: "Active", value: "ACTIVE" },
          { label: "Trial", value: "TRIAL" },
        ]
  );
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState(defaultPage);

  const getData = async (pageSize, page) => {
    let historyFilters = {
      searchtext: search || null,
      filterData: {
        status: status || [],
        industryId: industryId ? industryId : null,
      },
      sortedBy: sortedArray,
      limit: pageSize,
      offset: page,
    };
    queryHistory.set(
      props,
      "getSystemClientsPaginationAndGroupByQuery",
      historyFilters
    );

    let newQueryVariables = {
      searchtext: search || null,
      filterData: {
        status: status && status.length ? status.map((i) => i.value) : [],
        industryId: industryId ? industryId : null,
      },
      sortedBy: sortedArray,
      limit: pageSize,
      offset: page,
    };

    refetch(newQueryVariables)
    // .then((i)=>{
    //   setSystemClientList(i && i.data && i.data.systemClientsPaginationAndGroupBy && i.data.systemClientsPaginationAndGroupBy.systemClients);
    //   setPages(
    //     i && i.data && i.data.systemClientsPaginationAndGroupBy.systemClients
    //       ? Math.ceil(
    //           i && i.data && i.data.systemClientsPaginationAndGroupBy.rowCount / defaultPageSize
    //         )
    //       : 1
    //   );
    // });
  };

  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const handleAnchorElMenuOpen = (event) => {
    setAnchorEl(divRef.current);
  };
  const handleAnchorElMenuClose = () => {
    setAnchorEl(null);
  };

  const redirectToDetailPage = (Id) => {
    const { history } = props;
    history.push(`/admin/clients/ClientsDetails/${Id}`);
  };

  const clearAllData = async () => {
    queryHistory.clear(props);
    status=[];
    setIndusryId(0);
    setStatus([]);
    setAnchorEl(null);
    industryId = 0;
    anchorEl = null;

    getData(defaultPageSize, defaultPage);
  };

  const handleChipClear = (type) => {
    queryHistory.clear(props);
    if (type === "industryType") {
      setIndusryId(0);
    } else if (type === "status") {
      setStatus("All");
    }
  };

  var height = window.innerHeight - 150;
  let statusString = "Status : ";
  let typeString = "Type : ";
  // let industryType = industryId
  //   ? industryListQuery &&
  //     industryListQuery.data.industry &&
  //     industryListQuery.data.industry.length > 0 &&
  //     industryListQuery.data.industry.filter(
  //       (i) => i.id === industryId
  //     )[0].industryDisplayName
  //   : "";
  let open = Boolean(anchorEl);
  const statusOptions = [
    {
      value: "TRIAL",
      label: "Trial",
    },
    {
      value: "ACTIVE",
      label: "Active",
    },
    {
      value: "EXPIRED",
      label: "Expired",
    },
    {
      value: "CANCELLED",
      label: "Cancelled",
    },
    {
      value: "SUSPENDED",
      label: "Suspended",
    },
    {
      value: "DELETED",
      label: "Deleted",
    },
  ];
  return (
    <div>
      {systemClientQueryLoading && <Spinner />}

      <div className="reactTableWraper">
        <div className="header-Wraper justifyContentEnd">
          <div className="header-Wraper-right" />
          <div className="header-Wraper-right" />
          <div className="header-Wraper-right">
            {/* {filterState && status !== 0 && (
              <Chip
                style={{
                  marginRight: 10,
                  background: "#EBF2FF",
                  borderRadius: 5,
                  maxHeight: 26,
                  borderColor: "white",
                }}
                label={
                  statusString + status && status.length ? status.map((i)=>i.label).join(", ") : ""                  
                }
                // onDelete={handleChipClear("status")}
                variant="outlined"
              />
            )} */}
            {/* {filterState && industryId > 0 && (
                <Chip
                  style={{
                    marginRight: 10,
                    background: "#EBF2FF",
                    borderRadius: 5,
                    maxHeight: 26,
                    borderColor: "white"
                  }}
                  // onDelete={handleChipClear("industryType")}
                  label={typeString + industryType}
                  variant="outlined"
                />
              )}  */}
            <div className="header-Wraper-filterDiv">
              <Button
                ref={divRef}
                aria-describedby={"popover"}
                variant="contained"
                color="primary"
                onClick={handleAnchorElMenuOpen}
              >
                <Icon>filter_alt</Icon>
              </Button>
              <div className="popoverOverflow">
                <Popover
                  id="popover"
                  open={open}
                  anchorEl={anchorEl}
                  classes={{
                    paper: "popoverOverflow",
                  }}
                  onClose={handleAnchorElMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div className="popoverContentwraper">
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAnchorElMenuClose();
                        }}
                        style={{ color: "#0077C5", cursor: "pointer" }}
                      >
                        Cancel
                      </a>
                    </div>
                    <div className="popoverFormDatePickerWraper">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div
                            style={{ position: "relative", zIndex: 999}}
                            className="w-full mb-16"

                          >
                             <Typography
                              // variant="h4"
                              // color="inherit"
                              style={{marginBottom:5}}
                              className="w-full mb-8 text-14"
                            >
                              Status
                            </Typography>
                            <FormControl
                              style={{
                                display: "flex",
                                zIndex: 99999,
                                position: "relative",
                              }}
                              variant="outlined"
                              className="w-full"
                              size="small"
                            >
                              <Select
                                onChange={(entry) => {
                                  setStatus(entry);
                                }}
                                isClearable={true}
                                isSearchable={true}
                                value={status}
                                isMulti={true}
                                name="colors"
                                options={statusOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div
                            style={{ position: "relative" }}
                            className="w-full mb-16"
                          >
                            <Typography
                            style={{marginBottom:5}}
                              className="w-full mb-8 text-14"
                            >
                              Type
                            </Typography>
                            <MUISelect
                              variant="outlined"
                              size="small"
                              id="type"
                              key={industryId}
                              className="w-full inputH-38"
                              value={industryId}
                              onChange={(e) => {
                                setIndusryId(e.target.value);
                              }}
                            >
                              <MenuItem value={0} disabled>
                                Select Type
                              </MenuItem>

                              {industryListQuery &&
                                industryListQuery.data &&
                                industryListQuery.data.industry &&
                                industryListQuery.data.industry &&
                                industryListQuery.data.industry.map(
                                  (item, idx) => {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        <span>
                                          <i
                                            className="statusDisplayColor"
                                            style={{
                                              background: item.color,
                                            }}
                                          />
                                          {item.industryDisplayName}
                                        </span>
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </MUISelect>
                          </div>
                        </Grid>
                        {/* <Grid item xs={12}> */}
                        {/* <DatePicker
                            selected={this.state.fromDate}
                            popperPlacement="top-bottom"
                            onChange={(date) => {
                              this.setState({
                                fromDate: date,
                              });
                            }}
                            placeholderText={"Renewal From Date"}
                            dateFormat="MM/dd/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onFocus={(e) => {
                              if (e) {
                                e.target.select();
                                this.setState({ vDateOutlineLable: true });
                              }
                            }}
                            onClickOutside={() => {
                              this.setState({ vDateOutlineLable: false });
                            }}
                          /> */}
                        {/* </Grid>
                        <Grid item xs={12}> */}
                        {/* <DatePicker
                            selected={this.state.toDate}
                            popperPlacement="top-bottom"
                            onChange={(date) => {
                              this.setState({
                                toDate: date,
                              });
                            }}
                            placeholderText={"Renewal To Date"}
                            dateFormat="MM/dd/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onFocus={(e) => {
                              if (e) {
                                e.target.select();
                                this.setState({ vDateOutlineLable: true });
                              }
                            }}
                            onClickOutside={() => {
                              this.setState({ vDateOutlineLable: false });
                            }}
                          /> */}
                        {/* </Grid> */}
                      </Grid>
                    </div>
                    <div className="popoverWraperFooter">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="cancelBtn"
                        onClick={() => {
                          setFilterState(false);
                          clearAllData();
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="filterBtn"
                        onClick={() => {
                          setFilterState(true);
                          queryHistory.clear(props);
                          handleAnchorElMenuClose();
                          getData(defaultPageSize, defaultPage);
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>

            <div className="formInputWraper">
              <Input
                placeholder={"Search"}
                className="flex flex-1 w-full"
                disableUnderline
                // fullWidth
                inputProps={{
                  "aria-label": "Search",
                  className: "inputStyle",
                }}
                value={search}
                onChange={async (e) => {
                  if (e.target.value) {
                    search = e.target.value;
                    setSearch(e.target.value);
                  } else {
                    await setSearch("");
                    search = "";
                    getData(defaultPageSize, defaultPage);
                  }
                }}
                onKeyPress={(e) => {
                  if (e && e.key === "Enter") {
                    getData(defaultPageSize, defaultPage);
                  }
                }}
              />
              <IconButton
                color="inherit"
                onClick={(ev) => {
                  ev.stopPropagation();
                  getData(defaultPageSize, defaultPage);
                }}
              >
                <Icon>search</Icon>
              </IconButton>
            </div>
          </div>
        </div>
        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={pages} // Display the total number of pages
          loading={systemClientQueryLoading} // Display the loading overlay when we need it
          onFetchData={fetchData} // Request new data when things change
          data={systemClientList}
          expanderDefaults={{ width: 0 }}
          columns={columns}
          resizable={true}
          noDataText="No Record Found"
          minRows={1}
          defaultSorted={sortedArray}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          page={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          onPageSizeChange={(sz) => {
            setCurrentPage(0);
            setPageSize(sz);
          }}
          multiSort={true}
          style={{
            maxHeight: height - 50,
            cursor: "pointer",
          }}
          getTrProps={(state, rowInfo, column, instance, item) => {
            return {
              onClick: (ev) => {
                redirectToDetailPage(rowInfo.original.id);
              },
            };
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps)(Clients);
