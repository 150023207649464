import React, { useState } from "react";
import {
  Grid,
  FormControlLabel,
  MenuItem,
  InputLabel,
  DialogContent,
  DialogActions,
  FormHelperText,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  Select as MUISelect,
} from "@material-ui/core";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  editPriceToSignuPlanPricelistMutation,
  addPriceToSignuPlanPricelistMutation,
  priceDetailsByIdRootQuery,
} from "./signupPlansGQLQuery";
import AmountInput from "../common/AmountInput";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";

const PriceModal = (props) => {
  const {
    data: priceDetailsByIdData,
    loading: priceDetailsByIdLoading,
    refetch: priceDetailsByIdRefetch,
    error: priceDetailsByIdError,
    fetchMore: priceDetailsByIdFetchMore,
  } = useQuery(priceDetailsByIdRootQuery, {
    variables: {
      priceId: parseInt(props.priceId) || null,
    },
    onCompleted: (data) => {
      if (
        priceDetailsByIdData &&
        priceDetailsByIdData.priceDetailsById &&
        priceDetailsByIdData.priceDetailsById.id !== null
      ) {
        setInitForm({
          id: priceDetailsByIdData.priceDetailsById.id,
          renewal: priceDetailsByIdData.priceDetailsById.renewal,
          price: priceDetailsByIdData.priceDetailsById.price,
          commitment: priceDetailsByIdData.priceDetailsById.commitment,
        });
      }
    },
    fetchPolicy: "network-only",
  });
  const [addPriceToSignuPlanPricelist] = useMutation(
    addPriceToSignuPlanPricelistMutation
  );
  const [editPriceToSignuPlanPricelist] = useMutation(
    editPriceToSignuPlanPricelistMutation
  );
  const [initForm, setInitForm] = useState({
    id: 0,
    duplicateMsg: "",
    showDuplicateError: false,
    renewal: "MONTHLY",
    price: 0,
    commitment: "NO COMMITMENT",
  });
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [selectedModule, setSelectedModule] = useState({});
  const [moduleId, setModuleId] = useState(null);
  const [duplicateMsg, setDuplicateMsg] = useState("");
  const formik = useFormik({
    initialValues: initForm,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      renewal: Yup.string().required("Please select renewal mode"),
      price: Yup.number()
        .min(1, "please enter a valid price")
        .typeError("Please enter a valid price"),
      commitment: Yup.string().required("Please select a commitment"),
      reflectChange: Yup.boolean(),
    }),
  });

  const handleSubmit = (values) => {
    let priceData = {};
    priceData.id = values.id;
    priceData.planId = parseInt(props.signupPlanId);
    priceData.price = values.price;
    priceData.uuid = uuidv4();
    priceData.commitment = values.commitment;
    priceData.renewal = values.renewal;
    priceData.reflectChange = values.reflectChange;
    if (values.id > 0) {
      editPriceToSignuPlanPricelist({
        variables: { signupPlanPricedata: priceData },
      })
        .then((item) => {
          // if (
          //   item &&
          //   item.data &&
          //   item.data.editPriceToSignuPlanPricelist &&
          //   item.data.editPriceToSignuPlanPricelist.id
          // ) {
          if (props.onSuccess) {
            props.onSuccess();
          }
          props.handleClose();
          // }
          // console.log(item);
        })
        .catch((error) => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].message == "DUPLICATE"
          ) {
            setShowDuplicateError(true);
            setDuplicateMsg("Commitment already exists");
          }
        });
    } else {
      addPriceToSignuPlanPricelist({
        variables: { signupPlanPricedata: priceData },
      })
        .then((item) => {
          if (
            item &&
            item.data &&
            item.data.addPriceToSignuPlanPricelist &&
            item.data.addPriceToSignuPlanPricelist.id
          ) {
            if (props.onSuccess) {
              props.onSuccess();
            }
            props.handleClose();
          }
        })
        .catch((err) => {
          if (
            err &&
            err.graphQLErrors &&
            err.graphQLErrors[0] &&
            err.graphQLErrors[0].message == "DUPLICATE"
          ) {
            setShowDuplicateError(true);
            setDuplicateMsg("Commitment already exists");
          }
          console.log(err);
        });
    }
  };
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };
  const { classes } = props;
  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
        style={{ paddingTop: 0 }}
      >
        <Grid container spacing={2} style={{ marginTop: 4 }}>
          <Grid
            direction="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              zIndex: 99999,
            }}
            item
            xs={12}
            className="p-0"
          >
            <FormControl
              component="fieldset"
              style={{ padding: 0, flexDirection: "row" }}
            >
              <RadioGroup
                aria-label={"Renewal"}
                name="renewal"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                color="primary"
                value={formik.values.renewal}
                onChange={(e) => {
                  formik.setFieldValue("renewal", e.target.value);
                  setDuplicateMsg("");
                  setShowDuplicateError(false);
                }}
              >
                <FormControlLabel
                  value="MONTHLY"
                  control={<Radio color="primary" />}
                  label={"Monthly"}
                />
                <FormControlLabel
                  value="YEARLY"
                  control={<Radio color="primary" />}
                  label={"Yearly"}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} style={{ zIndex: 99999 }} className="p-0">
                <FormControl
                  style={{ display: "flex" }}
                  variant="outlined"
                  className="w-full"
                  size="small"
                  required
                  error={
                    (formik.touched.commitment && formik.errors.commitment) ||
                    showDuplicateError
                  }
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    className={classes.lableOutline}
                  >
                    Select commitment
                  </InputLabel>
                  <MUISelect
                    value={formik.values.commitment}
                    className="display-flex w-full"
                    // fullWidth={true}
                    variant="outlined"
                    placeholder="commitment"
                    label="Commitment"
                    onChange={(e) => {
                      formik.setFieldValue("commitment", e.target.value);
                    }}
                  >
                    {/* <MenuItem disabled value={"default"}>
                              Select Commitment
                            </MenuItem> */}
                    <MenuItem value={"NO COMMITMENT"}>No commitment</MenuItem>
                    <MenuItem value={"1 YEAR"}>1 Year</MenuItem>
                    <MenuItem value={"2 YEAR"}>2 Year</MenuItem>
                    <MenuItem value={"3 YEAR"}>3 Year</MenuItem>
                    <MenuItem value={"4 YEAR"}>4 Year</MenuItem>
                    <MenuItem value={"5 YEAR"}>5 Year</MenuItem>
                  </MUISelect>
                  {(formik.errors.commitment && formik.touched.commitment) ||
                  showDuplicateError ? (
                    <FormHelperText>
                      {(formik.errors.commitment &&
                        formik.touched.commitment &&
                        formik.errors.commitment) ||
                        (showDuplicateError && duplicateMsg)}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <AmountInput
                  id="price"
                  name="price"
                  fixDigits={true}
                  label={"Price"}
                  value={formik.values.price}
                  onBlur={(value) => {
                    let val = value || 0;
                    formik.setFieldValue("price", val);
                    // this.setState({
                    //   setIsUserEnteredAmount: true,
                    // });
                  }}
                  helperText={
                    formik.errors.price &&
                    formik.touched.price &&
                    formik.errors.price
                  }
                  error={
                    formik.errors.price && formik.touched.price ? true : false
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              // console.log("Caleed")
              formik.handleSubmit();
            }}
            color="primary"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </form>
  );
};

PriceModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

const styles = (theme) => ({
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  panel: {
    position: "fixed",
    width: 360,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    top: 0,
    height: "100%",
    minHeight: "100%",
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    padding: "55px 0px 0px",
    transform: "translate3d(360px,0,0)",
    // overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      transform: "translate3d(360px,0,0)",
      boxShadow: "none",
      "&.opened": {
        boxShadow: theme.shadows[5],
      },
    },
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    "&.opened": {
      transform: "translateX(0)",
    },
  },
});

export default withStyles(styles)(connect(mapStateToProps)(PriceModal));
