/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { connect } from "react-redux";
import DraggableTable from "../common/DraggableTable";
import queryHistory from "../../lib/queryHistroy";
import DatePicker from "react-datepicker";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import {
  addSubscriptionJobsMutation,
  getSubscriptionJobsRootQuery
} from "./subscriptionJobGqlQuery";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { withToastManager } from "react-toast-notifications";
const defaultPageSize = 20;
const defaultPage = 1;

const styles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  textField: {
    width: "100%"
  },
  rowContainer: {
    margin: "0px 0px 15px 0px"
  },
});

class SubscriptionJob extends Component {
  constructor(props) {
    super(props);
    this.refReactTable = {};
    let historyVars =
      queryHistory.get(props, "getSubscriptionJobsRootQuery") || {};
    this.state = {
      open: false,
      queryDidLoad: false,
      defaultPage: defaultPage,
      pages: defaultPage,
      currentPage: historyVars.offset ? historyVars.offset - 1 : 0,
      initForm: {
        jobRunDate: null
      },
      pageSize: historyVars.limit ? historyVars.limit - 1 : defaultPageSize,
      
      columns: [
        {
          Header: "Job",
          Cell: props => {
            return <span>{props.original.jobName}</span>;
          }
        },
        {
          Header: "Last Job Run Date time",
          Cell: props => {
            return (
              <span>
                {props.original.lastJobRunDatetime
                  ? moment(props.original.lastJobRunDatetime).format(
                      "YYYY-MM-DD HH:mm A"
                    )
                  : ""}
              </span>
            );
          }
        },
        {
          Header: "Action",
          width: 100,
          Cell: props => {
            return (
              <div style={{display:"flex",alignItems: "center",justifyContent: "center"}}>
              <Button
                onClick={() => {
                  this.handleClickOpen(props.original);
                }}
                color="primary"
                variant="contained"
                className="primaryButton"
              >
                Run
              </Button>
              </div>
            );
          }
        }
      ]
    };
  }

  handleClickOpen = data => {
    this.setState({
      open: true,
      headerDialogTitle: data.jobName,
      initForm: {
        jobRunDate: null
      }
    });
  };

  handleClose = () => {
    this.setState({
      initForm: {
        jobRunDate: null
      },
      open: false,
      // headerDialogTitle: ""
    });
  };


  handleSubmit = values => {
    let data = {
      jobName: this.state.headerDialogTitle,
      manualRunJobRunDate: moment(values.jobRunDate)
        .tz("UTC")
        .format("YYYY-MM-DD HH:mm:ss")
    };
    // console.log("hdl submit data: ", data);

    this.props.addSubscriptionJobsMutation({
      variables: {
        subscriptionJobData: data
      },
      refetchQueries: [{ query: getSubscriptionJobsRootQuery }]
    }).then((item)=> {
      // console.log(item)
    })
  
    this.handleClose();
  };

  fetchData = async (state, instance) => {
    this.refReactTable = instance;
    this.getData(state.pageSize, state.page + 1);
  };

  getData = async (pageSize, page) => {
    if (this.props.getSubscriptionJobsRootQuery.loading) {
      return;
    }
    try {
      let queryVariables = {};
      queryHistory.set(
        this.props,
        "getSubscriptionJobsRootQuery",
        queryVariables
      );
      this.props.getSubscriptionJobsRootQuery
        .refetch(queryVariables)
        .then(i => {
          this.setState({
            // pages: 1,
            // pageSize: pageSize,
            loading: false
          });
        });
    } catch (err) {
      console.log("err---- ", err);
    }
  };

  initializeScreen = () => {
    if (
      !this.props.getSubscriptionJobsRootQuery.loading &&
      !this.state.queryDidLoad
    ) {
      
          this.setState({
            queryDidLoad: true
          });
    
    }
  };

  render() {
    var height = window.innerHeight - 115;
    this.initializeScreen();
    const { classes } = this.props;
    return (
      <div className="reactTableWraper">
        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={this.state.pages} // Display the total number of pages
          loading={this.props.getSubscriptionJobsRootQuery.loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          data={
            !this.props.getSubscriptionJobsRootQuery.loading
              ? this.props.getSubscriptionJobsRootQuery.subscriptionJobs
              : []
          }
          expanderDefaults={{ width: 0 }}
          columns={this.state.columns}
          resizable={true}
          noDataText={"No record found"}
          minRows={1}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={20}
          pageSize={this.state.pageSize}
          page={this.state.currentPage}
          onPageChange={page => {
            this.setState({ currentPage: page });
          }}
          onPageSizeChange={sz => {
            this.setState({
              currentPage: 0,
              pageSize: sz
            });
          }}
          multiSort={true}
          collapseOnDataChange={false}
          style={{
            maxHeight: height,
          }}
        />

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title" className="mainDialogTitle">
            {this.state.headerDialogTitle}
          </DialogTitle>
          <Formik
            initialValues={this.state.initForm}
            validateOnBlur={false}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
            onReset={() => {}}
            validationSchema={Yup.object().shape({
              jobRunDate: Yup.date()
                .nullable()
                .required("Please select date")
            })}
            enableReinitialize={true}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <DialogContent
                    style={{ background: "#fff", padding: "20px 16px" }}
                  >
                    <div style={{ width: "320px", height: "350px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={3}
                        className={classes.rowContainer}
                      >
                        <div className="fullWidthDateTime dateComponent">
                          <DatePicker
                            selected={values.jobRunDate}
                            popperPlacement="top-bottom"
                            onChange={jobRunDate => {
                              setFieldValue("jobRunDate", jobRunDate);
                            }}
                            error={
                              touched.jobRunDate && errors.jobRunDate
                                ? true
                                : false
                            }
                            helperText={
                              errors.jobRunDate &&
                              touched.jobRunDate &&
                              errors.jobRunDate
                            }
                            placeholderText={"Date"}
                            dateFormat="MMMM d, yyyy"
                            className={
                              errors.jobRunDate
                                ? "datePickerComponent errorDisplay"
                                : "datePickerComponent"
                            }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onFocus={e => {
                              if (e) {
                                e.target.select();
                                this.setState({ vDateOutlineLable: true });
                              }
                            }}
                            onClickOutside={() => {
                              this.setState({ vDateOutlineLable: false });
                            }}
                          />
                          <span
                            className={
                              this.state.vDateOutlineLable
                                ? "dateInputLable outLineDateInputLable"
                                : "dateInputLable"
                            }
                          >
                            {"Date"}
                          </span>
                          {errors.jobRunDate === "Please select date" && (
                            <span
                              style={{
                                color: "red",
                                display: "block",
                                fontSize: 13,
                                marginTop: 3
                              }}
                            >
                              {errors.jobRunDate}
                            </span>
                          )}
                        </div>
                      </Grid>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        this.handleClose();
                      }}
                      variant="contained"
                      color="default"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      variant="contained"
                    >
                      Run
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </div>
    );
  }
}

SubscriptionJob.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({}) => {
  return ({});
};

export default withStyles(styles)(
  connect(mapStateToProps)(
    compose(
      graphql(getSubscriptionJobsRootQuery, {
        name: "getSubscriptionJobsRootQuery"
      }),
      graphql(addSubscriptionJobsMutation, {
        name: "addSubscriptionJobsMutation"
      })
    )(withToastManager(SubscriptionJob))
  )
);
