import React, { useEffect, useState } from "react";
import { signupPlanModuleBySignupPlanIdRootQuery } from "./signupPlansGQLQuery";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import queryHistory from "../../lib/queryHistroy";
import DraggableTable from "../common/DraggableTable";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import ModuleFuncationalityListModal from "./ModuleFuncationalityListModal";

const defaultSorted = [
  {
    id: "module_display_name",
    desc: false,
  },
];
const defaultPageSize = 20;
const defaultPage = 1;
const ModuleList = (props) => {

  const signupPlanPriceListBySignupPlanIdRefetchOnCall=()=>{
    signupPlanModuleBySignupPlanIdRefetch()    
  }

  useEffect(()=>{    
    props.bindSignupPlanModuleListBySignupPlanIdRootQueryRefetchOnCall(signupPlanPriceListBySignupPlanIdRefetchOnCall)
  },[signupPlanPriceListBySignupPlanIdRefetchOnCall])

  
  const { classes } = props;
  const {
    data: signupPlanModuleBySignupPlanIdData,
    loading: signupPlanModuleBySignupPlanIdLoading,
    refetch : signupPlanModuleBySignupPlanIdRefetch,
    error: signupPlanModuleBySignupPlanIdError,
    fetchMore: signupPlanModuleBySignupPlanIdFetchMore,
  } = useQuery(signupPlanModuleBySignupPlanIdRootQuery, {
    variables: {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    // onCompleted: (data) => {
    //   console.log("data", data);
    //   setSignupPlanModulesList(
    //     data && data.signupPlanModuleBySignupPlanId && data.signupPlanModuleBySignupPlanId.signupPlanModules
    //       .length > 0
    //       ? cloneDeep(
    //           data.signupPlanModuleBySignupPlanId.signupPlanModules
    //         )
    //       : []
    //   );
    //   setPages(
    //     data && data.signupPlanModuleBySignupPlanId && data.signupPlanModuleBySignupPlanId.signupPlanModules
    //       ? Math.ceil(
    //           data.signupPlanModuleBySignupPlanId.rowCount /
    //             defaultPageSize
    //         )
    //       : 1
    //   );
    // },
    fetchPolicy: "network-only",
  });

  useEffect(() => {    
    if (
      signupPlanModuleBySignupPlanIdData &&
      signupPlanModuleBySignupPlanIdData.signupPlanModuleBySignupPlanId &&
      signupPlanModuleBySignupPlanIdData.signupPlanModuleBySignupPlanId
        .signupPlanModules
    ) {
      setSignupPlanModulesList(
        signupPlanModuleBySignupPlanIdData.signupPlanModuleBySignupPlanId
          .signupPlanModules
      );
      setPages(
        signupPlanModuleBySignupPlanIdData.signupPlanModuleBySignupPlanId
          .signupPlanModules
          ? Math.ceil(
              signupPlanModuleBySignupPlanIdData
                .signupPlanModuleBySignupPlanId.rowCount / defaultPageSize
            )
          : 1
      );
    } else {
      setSignupPlanModulesList([]);
    }
  }, [signupPlanModuleBySignupPlanIdData]);

  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const [signupPlanModulesList, setSignupPlanModulesList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const [openModuleDetail, setOpenModuleDetail] = useState(false);
  const [ moduleData, setModuleData] = useState(0);

  const columns = [
    {
      HeaderText: "Module Display Name",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Module Display Name"} 
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "module_display_name").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "module_display_name",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize" style={{cursor: "pointer" }} onClick={()=>{setOpenModuleDetail(true); setModuleData({id: props.original.moduleId, name : props.original.moduleDisplayName});}}>
            {props.original.moduleDisplayName.charAt(0) +
              props.original.moduleDisplayName.slice(1).toLowerCase()}
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Module Name",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Module Name"}
          </span>
        </div>
      ),
      id: "module_name",
      Cell: (props) => {        
        return (
          <div className="flex items-center capitalize" style={{cursor: "pointer" }} onClick={()=>{setOpenModuleDetail(true); setModuleData({id: props.original.moduleId, name : props.original.moduleDisplayName});}}>
            {props.original.moduleName.charAt(0) +
              props.original.moduleName.slice(1).toLowerCase()}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>Type</Typography>
          </span>
        </div>
      ),
      Cell: (props) => {
        return (
          <>
            <span
              style={props.original.isAddonModule ? { color: "green" } : {}}
              className="flex items-center capitalize"
            >
              {props.original.isAddonModule ? "Add-on" : "Included"}
            </span>
          </>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>Price</Typography>
          </span>
        </div>
      ),
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.price > 0
                ? "$ " + props.original.price + " / Month"
                : "-"}
            </span>
          </>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>Is Active?</Typography>
          </span>
        </div>
      ),
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.isActive ? (
                <Icon style={{ color: "green" }}>check</Icon>
              ) : (
                ""
              )}
            </span>
          </>
        );
      },
      sortable: false,
      multiSort: false,
    },
  ];

  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: sortedArray,
      limit: pageSize || 20,
      offset: page || 1,
    };

    let historyFilters = {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: sortedArray,
      limit: pageSize || 20,
      offset: page || 1,
    };
    queryHistory.set(
      props,
      "getSystemClientsPaginationAndGroupByQuery",
      historyFilters
    );
    signupPlanModuleBySignupPlanIdRefetch(newQueryVariables);
  };

   var height = props.height || window.innerHeight - 165;
  return (
    <>
    <div style={{ padding: 8 }} className="reactTableWraper">
      <DraggableTable
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        pages={pages} // Display the total number of pages
        loading={signupPlanModuleBySignupPlanIdLoading} // Display the loading overlay when we need it
        onFetchData={fetchData} // Request new data when things change
        data={signupPlanModulesList}
        expanderDefaults={{ width: 0 }}
        columns={columns}
        resizable={true}
        noDataText="No Record Found"
        minRows={1}
        defaultSorted={sortedArray}
        pageSizeOptions={[20, 25, 50, 100]}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        onPageSizeChange={(sz) => {
          setCurrentPage(0);
          setPageSize(sz);
        }}
        multiSort={true}
        style={{
          maxHeight: height,          
          cursor: "default",
        }}      
      />
    </div>

    {/* ------------------------Start extend trial dialog---------------------------- */}
    <Dialog
        fullWidth={true}
        scroll={"paper"}
        maxWidth="xs"
        style={{ overflow: "hidden" }}
        disableBackdropClick
        open={openModuleDetail}
        onEscapeKeyDown={()=>{
          setOpenModuleDetail(false);
        }}
        onClose={()=>{setOpenModuleDetail(false);}}
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
      >
          <DialogTitle
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {"Module Funcationality"}
              </Typography>
              <IconButton onClick={()=>{setOpenModuleDetail(false);}}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
          classes={{
            root: classes.dialogContentRoot,
          }}
        >          
            <ModuleFuncationalityListModal
              handleClose={()=>{setOpenModuleDetail(false)}}
              moduleData = {moduleData}
            />
          </DialogContent>
        </Dialog> 
        {/* ------------------------End extend trial dialog---------------------------- */}
        </>
  );
};

const styles = () => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});

const mapStateToProps = ({}) => {
  return {};
};

export default withStyles(styles)(connect(mapStateToProps)(ModuleList));
