/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Fab,
  TextField,
  FormControl,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Dialog,
  Grid,
  Input,
  IconButton
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import ReactTable from "react-table";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import {
  getMultilingualQuery,
  addMultilingualMutation,
  editMultilingualMutation,
  deleteMultilingualMutation
} from "./multilingualGqlQuery";
import { withToastManager } from "react-toast-notifications";
import matchSorter from "match-sorter";
import { FieldArray, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import "react-table/react-table.css";
import DraggableTable from "../common/DraggableTable";
import queryHistory from "../../lib/queryHistroy";
import ActionOptionsButton from "../common/ActionOptionsButton";
import ConfirmDialog from "../common/ConfirmDialog";

const defaultPageSize = 20;
const defaultPage = 1;

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  title: {
    fontSize: "14px",
    display: "block",
    flexGrow: "0",
    maxWidth: "100%",
    flexBasis: "100%",
    margin: "15px 0px 10px"
  },
  textCenter: {
    textAlign: "center"
  },
  paperFullWidth: {
    overflowY: "visible"
  },
  dialogContentRoot: {
    overflowY: "visible"
  }
});

class Multilingual extends Component {
  constructor(props) {
    super(props);
    this.refReactTable = {};

    let historyVars = queryHistory.get(props, "getMultilingualQuery") || {};

    this.state = {
      multilingualList: [],
      queryDidLoad: false,
      defaultPage: defaultPage,
      pages: defaultPage,
      currentPage: historyVars.offset ? historyVars.offset - 1 : 0,
      pageSize: historyVars.limit ? historyVars.limit : defaultPageSize,
      labelEdit: false,
      openDialog: false,
      deleteDialog: false,
      searchtext: historyVars.searchtext,
      initFormData: {
        systemlabel: "",
        industryarry: [],
        id: 0
      },
      titleName: "Add System label",
      columns: [
        {
          Header: "System label",
          accessor: "systemLabel",
          filterable: false,
          sortable: false
        },
        {
          Header: "Industry",
          accessor: "industryName",
          maxWidth: 200,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["industryName"] }),
          filterAll: true,
          filterable: false,
          sortable: false
        },
        {
          Header: "US English",
          accessor: "usEnglish",
          maxWidth: 200,
          filterable: false,
          sortable: false
        },
        {
          Header: "UK English",
          accessor: "ukEnglish",
          maxWidth: 200,
          filterable: false,
          sortable: false
        },
        {
          Header: "Spanish",
          accessor: "spanish",
          maxWidth: 200,
          filterable: false,
          sortable: false
        },
        {
          Header: "Hindi",
          accessor: "hindi",
          maxWidth: 200,
          filterable: false,
          sortable: false
        },
        {
          Header: "Action",
          filterable: false,
          sortable: false,
          Cell: (rowData) => {
            let options = [];
            options.push({
              label: "Edit",
              callBack: (ev) => {
                // ev.stopPropagation();
                this.handleEdit(rowData.original);
              }
            });
            options.push({
              label: (
                <ConfirmDialog
                  title={"Confirm Delete"}
                  body={"Are you sure you want to delete this record?"}
                  open={true}
                  firstButton={"Cancel"}
                  secondButton={"Delete"}
                  onConfirm={() => {                    
                    this.setState({                                        
                      deleteObj: rowData.original,
                    },()=>{
                      this.handleDelete();
                    });
                  }}
                  onReject={() => {}}
                >
                  Delete
                </ConfirmDialog>
              )
            });
            return <ActionOptionsButton options={options} />;
          }
        }
      ]
    };
  }

  componentDidMount = () => {
    axios
      .get(`${config.api.gatewayService}/systemclient/industries`, {})
      .then((res) => {
        this.setState({
          initFormData: {
            systemlabel: "",
            industryarry: res.data.data.map((i) => {
              i.industryId = i.id;
              i.usEnglish = "";
              i.ukEnglish = "";
              i.spanish = "";
              i.hindi = "";
              return i;
            })
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  renderIndustry = () => {
    return this.state.initFormData.industryarry.map((i, idx) => {
      return (
        <option value={i.id} key={idx}>
          {i.industryName}
        </option>
      );
    });
  };
  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onInputlabelChange = (name, index, e) => {
    let arry = this.state.initFormData.industryarry;
    arry[index][name] = e.target.value;
    this.setState({ industryarry: arry });
  };

  handleClickOpen = () => {
    this.componentDidMount();
    this.setState({
      openDialog: true,
      labelEdit: false,
      dataEdit: false,
      initFormData: {
        systemlabel: "",
        industryarry: this.state.initFormData.industryarry.map((a) => {
          a["usEnglish"] = "";
          a["ukEnglish"] = "";
          a["spanish"] = "";
          a["hindi"] = "";
          return a;
        })
      },
      titleName: "Add System label"
    });
  };

  handleClose = () => {
    this.setState({
      openDialog: false,
      dataEdit: false
    });
    this.componentDidMount();
  };
  handleDeleteClose = () => {
    this.setState({
      deleteDialog: false,
      deleteObj: {}
    });
  };
  handleEdit = (e) => {
    this.setState({
      openDialog: true,
      labelEdit: true,
      dataEdit: true,
      lableIndustryId: e.lableIndustryId,
      industryId: e.industryId,
      initFormData: {
        systemlabel: e.systemLabel,
        industryarry: this.state.initFormData.industryarry.map((a) => {
          let b = {};
          let multiLanguage = this.state.multilingualList.filter(
            (i) =>
              i.systemLabelId === e.systemLabelId &&
              i.industryId === a.industryId
          );
          b.lableIndustryId = multiLanguage.length
            ? multiLanguage[0].lableIndustryId
            : 0;
          b.id = multiLanguage.length ? multiLanguage[0].id : 0;
          b.systemLabelId = e.systemLabelId;
          b.systemLabel = multiLanguage.length
            ? multiLanguage[0].systemLabel
            : "";
          b.usEnglish = multiLanguage.length ? multiLanguage[0].usEnglish : "";
          b.ukEnglish = multiLanguage.length ? multiLanguage[0].ukEnglish : "";
          b.spanish = multiLanguage.length ? multiLanguage[0].spanish : "";
          b.hindi = multiLanguage.length ? multiLanguage[0].hindi : "";
          b.industryId = a.industryId;
          return { ...a, ...b };
        })
      },
      titleName: "Edit System label"
    });
  };

  handleDelete = (e) => {
    this.props
      .deleteMultilingualMutation({
        variables: {
          lableIndustryId: this.state.deleteObj.lableIndustryId,
          systemLabelId: this.state.deleteObj.systemLabelId
        }
      })
      .then((item) => {
        this.props.getMultilingualQuery.refetch().then((i) => {
          this.setState({
            multilingualList: !this.props.getMultilingualQuery.loading
              ? this.props.getMultilingualQuery.multilingual.multilingualList
              : [],
            pages: this.props.getMultilingualQuery.multilingual
              ? Math.ceil(
                  this.props.getMultilingualQuery.multilingual.rowCount / 20
                )
              : 1,
            pageSize: 20,
            loading: false,
          })
        })
          .catch((err)=>console.log(err))
          
        this.setState({ deleteObj: {} });
        this.props.toastManager.add("Label deleted successfully", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false
        });
      })
      .catch((err) => {
        this.setState({  deleteObj: {} });
        console.log(err);
      });
  };

  handleSubmit = (dataValue) => {
    if (this.state.dataEdit) {
      var data = dataValue.industryarry.filter((i) => {
        return i.industryId;
      });
      var industryData = data.map((a) => {
        let b = {};
        // b.id = a.id ? a.id : 0;
        // b.systemLabel = dataValue.systemlabel ? dataValue.systemlabel : "";
        b.systemLabelId = dataValue.industryarry[0].systemLabelId;
        b.lableIndustryId = a.lableIndustryId ? a.lableIndustryId : 0;
        b.industryId = a.industryId;
        b.hindi = a.hindi ? a.hindi : "";
        b.spanish = a.spanish ? a.spanish : "";
        b.ukEnglish = a.ukEnglish ? a.ukEnglish : "";
        b.usEnglish = a.usEnglish ? a.usEnglish : "";
        return b;
      });
      
      this.props
        .editMultilingualMutation({
          variables: {
            multilingual: industryData
          }
        })
        .then((item) => {
          this.getData(defaultPageSize, defaultPage);
          this.setState({
            lableIndustryId: 0,
            openDialog: false,
            dataEdit: false
          });
          this.props.toastManager.add("Label Updated Successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: false
          });
          this.componentDidMount();
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    } else {
      var multilingualFormData = {
        systemLabel: dataValue.systemlabel,
        industryArray: dataValue.industryarry.map((i) => {
          delete i.id;
          delete i.industryDisplayName;
          delete i.createdDatetime;
          return i;
        })
      };
      this.props
        .addMultilingualMutation({
          variables: {
            multilingual: multilingualFormData
          },
          refetchQueries: [{ query: getMultilingualQuery }]
        })
        .then((item) => {
          this.setState({
            initFormData: {
              industryarry: this.state.initFormData.industryarry
            },
            openDialog: false
          });
          this.props.toastManager.add("Label added Successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: false
          });
        })
        .catch((error) => {
          this.setState({
            initFormData: {
              industryarry: this.state.initFormData.industryarry
            }
          });
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].message === "DUPLICATE"
          ) {
            this.props.toastManager.add("Label already exists", {
              appearance: "warning",
              autoDismiss: false,
              pauseOnHover: false
            });
          }
        });
    }
  };
  fetchData = async (state, instance) => {
    this.refReactTable = instance;
    if (this.props.getMultilingualQuery.loading) {
      return;
    }
    this.setState({ currentPage: state.page });

    this.getData(state.pageSize, state.page + 1);
  };

  getData = async (pageSize, page) => {
   
    let queryVariables = {
      limit: pageSize,
      offset: page,
      searchtext: this.state.searchtext || null
    };
    queryHistory.set(this.props, "getMultilingualQuery", queryVariables);

    this.props.getMultilingualQuery.refetch(queryVariables).then((i) => {
      this.setState({
        multilingualList: !this.props.getMultilingualQuery.loading
          ? this.props.getMultilingualQuery.multilingual.multilingualList
          : [],
        pages: this.props.getMultilingualQuery.multilingual
          ? Math.ceil(
              this.props.getMultilingualQuery.multilingual.rowCount / pageSize
            )
          : 1,
        pageSize: pageSize,
        loading: false
      });
    });
  };

  onFilterApply = () => {
    this.setState({
      currentPage: defaultPage - 1,
      pageSize: defaultPageSize
    }, () => {
      this.getData(defaultPageSize, defaultPage);
    })
  }

  initializeScreen = () => {
    if (!this.props.getMultilingualQuery.loading && !this.state.queryDidLoad) {
      this.setState(
        {
          pages: this.props.getMultilingualQuery.multilingual
            ? Math.ceil(
                this.props.getMultilingualQuery.multilingual.rowCount /
                  defaultPageSize
              )
            : 1,
          multilingualList: this.props.getMultilingualQuery.multilingual
            ? this.props.getMultilingualQuery.multilingual.multilingualList
            : []
        },
        () => {
          this.setState({
            queryDidLoad: true
          });
        }
      );
    }
  };
  render() {
    var height = window.innerHeight - 155;

    const { classes } = this.props;
    this.initializeScreen();
    return (
      <div className="reactTableWraper">
        <div className="header-Wraper justifyContentEnd">
          <div className="header-Wraper-right">
            <div className="formInputWraper">
              <Input
                placeholder={"Search"}
                className="flex flex-1 w-full"
                disableUnderline
                inputProps={{
                  "aria-label": "Search",
                  className: "inputStyle"
                }}
                value={this.state.searchtext}
                onChange={async (e) => {
                  if (e.target.value) {
                    this.setState({ searchtext: e.target.value });
                  } else {
                    await this.setState({ searchtext: "" });
                    this.onFilterApply();
                  }
                }}
                onKeyPress={(e) => {
                  if (e && e.key === "Enter") {
                    this.onFilterApply();
                  }
                }}
              />
              <IconButton
                color="inherit"
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.onFilterApply();
                }}
              >
                <Icon>search</Icon>
              </IconButton>
            </div>
          </div>
          <div className="addButtonStyle" style={{ marginLeft: 8 }}>
            <Button
              size="medium"
              variant="contained"
              className="btnSmall panelAddButton p-0"
              style={{
                maxWidth: 121,
                maxHeight: 34,
                backgroundColor: "#387DFF"
              }}
              onClick={this.handleClickOpen}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  textTransform: "capitalize"
                }}
              >
                Add Multilingual
              </Typography>
            </Button>
          </div>
        </div>

        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={this.state.pages} // Display the total number of pages
          loading={this.props.getMultilingualQuery.loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          data={this.state.multilingualList}
          expanderDefaults={{ width: 0 }}
          columns={this.state.columns}
          resizable={true}
          noDataText={"No record found"}
          minRows={1}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={this.state.pageSize}
          pageSize={this.state.pageSize}
          page={this.state.currentPage}
          onPageChange={(page) => {
            this.setState({ currentPage: page });
          }}
          onPageSizeChange={(sz) => {
            this.setState({
              currentPage: 0,
              pageSize: sz
            });
          }}
          multiSort={true}
          collapseOnDataChange={false}
          style={{
            maxHeight: height - 65
            // cursor: "pointer"
          }}
        />
        <Dialog open={this.state.deleteDialog} onClose={this.handleDeleteClose}>
          <DialogTitle>
            {"Are you sure you want to delete this record?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleDeleteClose} color="primary">
              Disagree
            </Button>
            <Button onClick={this.handleDelete} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        {/* <FormControl> */}
        <Dialog
          fullWidth={true}
          // scroll={"paper"}
          maxWidth="xs"
          // style={{ overflow: "hidden" }}
          disableBackdropClick
          classes={{
            paperFullWidth: classes.paperFullWidth
          }}
          open={this.state.openDialog}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff"
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {this.state.titleName}
              </Typography>
              <IconButton onClick={this.handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            style={{ overflowY: "auto" }}
            classes={{
              root: classes.dialogContentRoot
            }}
          >
            <Formik
              initialValues={this.state.initFormData}
              onSubmit={(values) => {
                this.handleSubmit(values);
              }}
              validationSchema={Yup.object().shape({
                systemlabel: Yup.string().required("System label is required"),
                industryarry: Yup.array().of(
                  Yup.object().shape({
                    usEnglish: Yup.string()
                      .required("Label is required")
                      .nullable(),
                    ukEnglish: Yup.string()
                      .required("Label is required")
                      .nullable(),
                    spanish: Yup.string()
                      .required("Label is required")
                      .nullable()
                    // hindi: Yup.string()
                    //   .required("Label is required")
                    //   .nullable()
                  })
                )
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} style={{ marginTop: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          variant="outlined"
                          fullWidth
                          size="small"
                          disabled={this.state.labelEdit}
                          error={errors.systemlabel && touched.systemlabel}
                          label="System label"
                          name="systemlabel"
                          // className={classes.textField}
                          value={values.systemlabel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.systemlabel &&
                            touched.systemlabel &&
                            errors.systemlabel
                          }
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                    <FieldArray name="industryarry">
                      <div>
                        {values.industryarry.map((iData, idx) => {
                          const usEnglish = `industryarry[${idx}].usEnglish`;
                          const touchedUsEnglish = getIn(touched, usEnglish);
                          const errorUsEnglish = getIn(errors, usEnglish);

                          const ukEnglish = `industryarry[${idx}].ukEnglish`;
                          const touchedUkEnglish = getIn(touched, ukEnglish);
                          const errorUkEnglish = getIn(errors, ukEnglish);

                          const spanish = `industryarry[${idx}].spanish`;
                          const touchedSpanish = getIn(touched, spanish);
                          const errorSpanish = getIn(errors, spanish);

                          const hindi = `industryarry[${idx}].hindi`;
                          const touchedHindi = getIn(touched, hindi);
                          const errorHindi = getIn(errors, hindi);

                          return (
                            <div key={idx}>
                              <Grid container>
                                <Typography
                                  variant="h6"
                                  className={classes.title}
                                >
                                  {idx + 1}. {iData.industryName}
                                </Typography>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  <TextField
                                    name={usEnglish}
                                    label="US English"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={iData.usEnglish}
                                    helperText={
                                      touchedUsEnglish && errorUsEnglish
                                        ? errorUsEnglish
                                        : ""
                                    }
                                    error={Boolean(
                                      touchedUsEnglish && errorUsEnglish
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name={ukEnglish}
                                    label="UK English"
                                    type="text"
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={iData.ukEnglish}
                                    helperText={
                                      touchedUkEnglish && errorUkEnglish
                                        ? errorUkEnglish
                                        : ""
                                    }
                                    error={Boolean(
                                      touchedUkEnglish && errorUkEnglish
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name={spanish}
                                    size="small"
                                    variant="outlined"
                                    label="Spanish"
                                    type="text"
                                    fullWidth
                                    value={iData.spanish}
                                    helperText={
                                      touchedSpanish && errorSpanish
                                        ? errorSpanish
                                        : ""
                                    }
                                    error={Boolean(
                                      touchedSpanish && errorSpanish
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name={hindi}
                                    label="Hindi"
                                    size="small"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    value={iData.hindi}
                                    helperText={
                                      touchedHindi && errorHindi
                                        ? errorHindi
                                        : ""
                                    }
                                    error={Boolean(touchedHindi && errorHindi)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          );
                        })}
                      </div>
                    </FieldArray>

                    <DialogActions>
                      <div
                        style={{ justifyContent: "flex-end", display: "flex" }}
                      >
                        <Button onClick={this.handleClose} color="primary">
                          Cancel
                        </Button>
                        <Button
                          // disabled={isSubmitting}
                          onClick={handleSubmit}
                          color="primary"
                        >
                          Save
                        </Button>
                      </div>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
        {/* </FormControl> */}
      </div>
    );
  }
}

Multilingual.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = () => {
  return {};
};
export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(
    compose(
      graphql(getMultilingualQuery, {
        name: "getMultilingualQuery",
        options: (props) => {
          let historyVars = queryHistory.get(props, "getMultilingualQuery");
          return {
            variables: historyVars
              ? historyVars
              : {
                  limit: defaultPageSize,
                  offset: defaultPage,
                  searchtext: null
                }
          };
        }
      }),
      graphql(addMultilingualMutation, { name: "addMultilingualMutation" }),
      graphql(editMultilingualMutation, { name: "editMultilingualMutation" }),
      graphql(deleteMultilingualMutation, {
        name: "deleteMultilingualMutation"
      })
    )(withToastManager(Multilingual))
  )
);
