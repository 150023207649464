import React, { Component } from "react";
import {
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  MenuItem,
  InputLabel,
  DialogContent,
  Checkbox,
  DialogActions,
  FormHelperText,
  Button,
  FormControl,
  Select as MUISelect,
} from "@material-ui/core";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import Select from "react-select";
import PropTypes from "prop-types";
import { FieldArray, Form, Formik, getIn } from "formik";
import { industryQuery, addPlanSignuPlanMutation } from "./signupPlansGQLQuery";

const animatedComponents = makeAnimated();
class PlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleIds: [],
      initForm: {
        id: 0,
        planName: "",
        industryId: 0,
        industryData: {},
        isPublished: false,
      },
    };
  }

  handleSubmit = (values) => {
    let planData = {};
    planData.id = values.id;
    planData.planName = values.planName
      ? values.planName.trim()
      : values.planName;
    planData.industryId = values.industryId;
    planData.isPublished = values.isPublished;
    if (values.id > 0) {
      //code for Edit
    } else {
      this.props
        .addPlanSignuPlanMutation({
          variables: { signupPlandata: planData },
        })
        .then((item) => {
          if (this.props.onSuccess) {
            this.props.onSuccess(
              item && item.data && item.data.addPlanSignuPlan.id
            );
          }
          this.props.handleClose();
        })
        .catch((err) => console.log("err"));
    }
  };

  render() {
    const { classes } = this.props;
    const colourStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        //   console.log({ data, isDisabled, isFocused, isSelected },styles);
        return {
          ...styles,
          backgroundColor: isFocused ? "#999999" : null,
          color: "#333333",
        };
      },
    };

    return (
      <Formik
        initialValues={this.state.initForm}
        validateOnBlur={false}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          id: Yup.number(),
          planName: Yup.string().required("Please enter plan name"),
          industryId: Yup.number()
            .min(1, "Please select industry")
            .required("Please select industry"),
          isPublished: Yup.boolean(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent
                classes={{
                  root: classes.dialogContentRoot,
                }}
                style={{ paddingTop: 0, zIndex: 9 }}
              >
                <Grid container spacing={2} style={{ marginTop: 4 }}>
                  <Grid item xs={10}>
                    <TextField
                      type="text"
                      value={values.planName}
                      fullWidth
                      id="planName"
                      variant="outlined"
                      autoFocus
                      required
                      className="p-0"
                      helperText={
                        errors.planName && touched.planName && errors.planName
                      }
                      error={errors.planName && touched.planName ? true : false}
                      label="Plan name"
                      size="small"
                      name="planName"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={10} className="p-0">
                      <FormControl
                        style={{ display: "flex" }}
                        variant="outlined"
                        className="w-full"
                        size="small"
                        error={errors.industryId && touched.industryId}
                      >
                        <Select
                          onChange={(entry) => {
                            setFieldValue("industryId", entry ? entry.id : 0);
                            setFieldValue("industryData", entry);
                            this.setState({
                              industryData: entry,
                              industryId: entry ? entry.id : 0,
                            });
                          }}
                          closeMenuOnSelect={true}
                          placeholder="Select industry name"
                          components={animatedComponents}
                          value={this.state.industryData}
                          menuPlacement="auto"
                          isClearable={true}
                          isSearchable={true}
                          styles={colourStyles}
                          options={
                            this.props.industryQuery &&
                            this.props.industryQuery.industry &&
                            this.props.industryQuery.industry.length > 0 &&
                            this.props.industryQuery.industry.map(
                              (qitem, idx) => {
                                let item = {...qitem}
                                item.label = item.industryDisplayName;
                                return item;
                              }
                            )
                          }
                        />
                        {errors.industryId && touched.industryId ? (
                          <FormHelperText
                            style={{
                              color: "#F44336",
                              wordBreak: "break-word",
                              marginLeft: 15,
                            }}
                          >
                            {errors.industryId &&
                              touched.industryId &&
                              errors.industryId}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="flex items-center">
                    <Grid container item xs={10}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Active"
                            onChange={(e) => {
                              setFieldValue("isPublished", e.target.checked);
                            }}
                            color="primary"
                            className="checkBoxprimary"
                          />
                        }
                        label={"Activate this plan?"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button onClick={this.props.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    // disabled={isSubmitting}
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Save
                  </Button>
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px",
  },
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});
PlanModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = () => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(
    compose(
      graphql(addPlanSignuPlanMutation, { name: "addPlanSignuPlanMutation" }),
      graphql(industryQuery, { name: "industryQuery" })
    )(PlanModal)
  )
);
